/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/10/2019
 * @Example
 */

import React from "react";
import * as mainActions from "../../../redux/main/main_actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class Controller extends React.Component {

    setFilter = (filter) => {
        this.props.mainActions.updateFilters(filter);
    };

    render() {

        return(
            <React.Fragment>
                <div />
            </React.Fragment>
        )
    }
}

export default connect(null,
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch)
    }), null, {forwardRef: true}
)(Controller);
