/**
 * @copyright 2019 @ DigiNet
 * @author TRANGHOANG
 * @create 1/21/2020
 */
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as W94F1000Actions from "../../../../redux/W9X/W94F1000/W94F1000_actions";
import Config               from "../../../../config";
import {Tabs, Tab, Icon}    from "@material-ui/core";
import {browserHistory}     from "react-router";
import * as mainActions     from "../../../../redux/main/main_actions";
import {Animated}           from "react-animated-css";
import {Button}             from "@material-ui/core";
import InlineSVG            from "react-inlinesvg";
import {Column}             from "devextreme-react/data-grid";
import GridContainer        from "../../../grid-container/grid-container";

const drawerWidth = '47%';
const heightHeader = 50;
const styles = theme => {
    return {
        drawer: {
            width: drawerWidth,
            maxWidth: 700,
            flexShrink: 0,
            [theme.breakpoints.down('sm')]: {
                zIndex: '1502 !important',
            },
        },
        drawerHeader: {
            // height: heightHeader,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '5px 0'
        },
        drawerPaper: {
            width: drawerWidth,
            maxWidth: 700,
            [theme.breakpoints.down(768)]: {
                width: '100%',
            },
            top: heightHeader,
            backgroundColor: '#3e3e3e',
            opacity: 1,
            // zIndex: 1502,
            padding: '0 15px 15px 15px',
            overflow: 'hidden',
            height: 'calc(100% - 50px)'
        },
        tabs: {
            maxWidth: 'calc(100% - 60px)',
        },
        tabContent: {
            padding: '10px 0',
            minHeight: 400
        },
        itemContent:{
            lineClamp:3,
            maxHeight: '4.2rem',
            whiteSpace:'pre-line',
            [theme.breakpoints.down(768)]: {
                lineClamp:2,
                maxHeight: '2.8rem',
            }
        },
        item:{
            width: 200,
            height: 260,
            borderRadius: 24,
            margin: 14,
            padding: 28,
            color: '#3E4050',
            position:'relative',
            overflow:'hidden',
            '& span':{
                fontSize:'2.85rem',
                lineHeight:'2.85rem',
                fontWeight: 700,
                // color: '#979797'
            },
            '&:hover .btn-hover':{
                display:'flex !important',
            },
            '& .btn-hover':{
                position: 'absolute',
                top:0,
                left:0,
                width:'100%',
                height:'100%',
                backgroundColor: 'rgba(0,0,0,0.4)',
                justifyContent: 'center',
                display:'none',
                '& span':{
                    border:'1px solid white',
                    borderRadius: 6,
                    fontSize: '1.7rem',
                    fontWeight: 300,
                    color:'white',
                    width: '60%',
                    display:'flex',
                    cursor:'pointer',
                    justifyContent: 'center',
                }
            },
            '& b':{
                fontSize: '1.4rem'
            },
            [theme.breakpoints.down(768)]: {
                width: '100%',
                height: 50,
                padding: '5px 10px',
                margin:'15px 0 0',
                flexDirection:'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                '& span':{
                    marginRight: 10,
                    fontSize:'2.2rem',
                },
                '& b':{
                    display: 'none'
                },
                '& .btn-hover span':{
                    borderRadius: 100,
                    fontSize: '0.8rem',
                    fontWeight: 300,
                },
            },
        },
        numberIdx:{
            width: 30,
            height: 30,
            borderRadius: 6,
            fontSize:14,
            display:'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin:'auto',
            fontWeight: 700
        },
        iconEye:{
            borderRadius: 100,
            padding: 3,
            margin:'auto',
            cursor:'pointer',
            '&:hover':{
                backgroundColor:'#E1E1E1'
            }
        },
    }
};

class W94F1000 extends React.Component {

    constructor(props) {
        super(props);
        const isGrid = Config.getLocalStorage("BI_VIEW") !== "list";
        this.state = {
            tabIdx:null,
            isGrid: isGrid ? isGrid : false
        };
    }

    componentDidMount() {
        const {location} = this.props;
        const {tabIdx} = this.state;
        this.props.W94F1000Actions.getGroupList({Lanuage: Config.language},(err, data)=>{
            if(err){
                Config.popup.show('INFO', err.message);
            }
            else if(data && data.infoGeneral && data.infoGeneral.length > 0 && !tabIdx){
                const isGrid = Config.getLocalStorage("BI_VIEW") !== "list";
                this.setState({
                    tabIdx: location && location.state && location.state.ReportGroupID ? location.state.ReportGroupID : data.infoGeneral[0].ReportGroupID,
                    isGrid: location && location.state && location.state.isGrid ? location.state.isGrid : isGrid
                });
                Config.filters.renderHTML(this.renderTab);
            }
        });
    }

    renderTab = () => {
        const {listGroupChart, classes} = this.props;
        const {tabIdx, isGrid} = this.state;
        const dataTab =listGroupChart && listGroupChart.infoGeneral ? listGroupChart.infoGeneral : [];
        if(!dataTab || !dataTab.length || !tabIdx) return null;
        return(
            <div className={'display_row align-center align-between'}>
                {!isGrid &&
                    <Tabs
                        indicatorColor={"primary"}
                        textColor={"primary"}
                        value={tabIdx}
                        onChange={this.onChangeTab}
                        className={classes.tabs}
                        variant="scrollable"
                        scrollButtons="on"
                        ScrollButtonComponent={this.renderButtonTab}
                    >
                        {dataTab && dataTab.map((tab, idx) => {
                            return (
                                <Tab key={idx}
                                     style={{minWidth: 70}}
                                     value={tab.ReportGroupID}
                                     label={tab.ReportGroupName}
                                />
                            )
                        })}
                    </Tabs>
                }
                {isGrid && <span style={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    textTransform: 'uppercase'
                }}>{Config.lang('BI_Danh_sach_bao_cao')}</span>}
                <div style={{zIndex:2, cursor:'pointer'}}
                     onClick={()=>{
                        this.setState({
                            isGrid: !this.state.isGrid
                        },()=>{
                            Config.setLocalStorage("BI_VIEW", this.state.isGrid ? "grid" : "list");
                            browserHistory.push({
                                pathname: Config.getRootPath() + 'W94F1000',
                                state: {
                                    ReportGroupID: tabIdx,
                                    isGrid: this.state.isGrid
                                }
                            });
                            Config.filters.renderHTML(this.renderTab);
                        })
                }} >
                    <InlineSVG width={14} height={14}
                               style={{cursor:'pointer'}}
                               src={isGrid ? require('../../../../assets/images/general/icon-grid.svg') : require('../../../../assets/images/general/icon-th-list.svg')}
                    />
                </div>
            </div>
        )
    };

    onChangeTab = (e, value) => {
        this.indexHidden=0;
        this.setState({tabIdx: value},()=>{
            Config.filters.renderHTML(this.renderTab);
            browserHistory.push({
                pathname: Config.getRootPath() + 'W94F1000',
                state: {
                    ReportGroupID: value,
                }
            })
        })
    };

    onLink = (item) => {
        if(!item) return null;
        if(item.ReportType === 'EMBED' && !item.EmbedCode){
            Config.popup.show('INFO', Config.lang('BI_No_link'));
            return;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + (item.ReportType === 'EMBED' ? 'W94F1001' : 'W94F1002'),
            state: {
                MReportID: item.MReportID,
                URL: item.URL,
                strSQL: item.strSQL,
                Sheet: item.Sheet,
                FileName: item.FileName,
            }
        })
    };

    renderButtonTab = (e) => {
        return (
            <Button variant={'text'}
                    color={'default'}
                    onClick={() => e.onClick()}
                    style={{
                        minWidth: 20, padding: 12, opacity: e.visible ? 1 : 0,
                        position:'absolute', top: 5, left: e.direction === 'left' ? -25 : 'auto',
                        right: e.direction === 'right' ? 25 : 'auto'
                    }}>
                <Icon style={{color: 'grey', opacity: 0.5, fontSize: 12}} className={e.direction === 'left' ? 'fas fa-chevron-left' : 'fas fa-chevron-right'}/>
            </Button>
        );
    };

    renderReportType = (data) => {
        const {listGroupChart} = this.props;
        const dataTab =listGroupChart && listGroupChart.infoGeneral ? listGroupChart.infoGeneral : [];
        const item = dataTab.find(i=>i.ReportGroupID === data.value);
        return (
            <div style={{fontWeight:200}}>{item && item.ReportGroupName}</div>
        );
    };

    renderReportName = (data) => {
        return (
            <div style={{fontWeight:200}}>{data.value}</div>
        );
    };

    render() {
        const {listGroupChart, classes, themes} = this.props;
        const {tabIdx, isGrid} = this.state;
        const dataList =listGroupChart && listGroupChart.Detail ? listGroupChart.Detail : [];
        this.indexHidden = 0;
        return (
            <div>
                <div className={'display_row flex-wrap'}>
                    {isGrid && (
                        <GridContainer
                            dataSource={dataList}
                            selection={{
                                mode: "single",
                            }}
                            onCellClick={(e)=>this.onLink(e.data)}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                        >
                            <Column
                                style={{ backgroundColor: "#b3bbc1" }}
                                caption={Config.lang("BI_So_thu_tu")}
                                sortOrder={'asc'}
                                cellRender={(data)=>{
                                    let indexColor =  'none';
                                    if(themes && themes.length > 0){
                                        indexColor = parseInt(data.value) % themes.length;
                                    }
                                    return(
                                        <span style={{
                                            backgroundColor: indexColor === 'none' ? 'white' : themes[indexColor].SubColor,
                                            color: indexColor === 'none' ? '#707070' : themes[indexColor].MainColor,
                                            border: indexColor === 'none' ? '1px solid #979797' : 'none',
                                        }} className={classes.numberIdx} >#{data.value}</span>
                                    )
                                }}
                                dataType={'number'}
                                dataField={"OrderNo"}
                                alignment={"center"}
                                width={"140"}
                            />
                            <Column
                                style={{ backgroundColor: "#b3bbc1" }}
                                caption={Config.lang("BI_Nhom_bao_cao")}
                                cellRender={this.renderReportType}
                                dataField={"ReportGroupID"}
                                alignment={"left"}
                            />
                            <Column
                                style={{ backgroundColor: "#b3bbc1" }}
                                caption={Config.lang("BI_Bao_cao")}
                                cellRender={this.renderReportName}
                                dataField={"MReportName"}
                                alignment={"left"}
                            />
                        </GridContainer>
                    )}
                    {!isGrid && dataList && dataList.map((item, idx)=>{
                    let indexColor =  'none';
                    if(themes && themes.length > 0){
                        indexColor = idx % themes.length;
                    }
                    const display =  tabIdx === item.ReportGroupID;
                    if(!display) this.indexHidden += 1;
                    return(
                        <Animated key={idx}
                                  animationIn="flipInX"
                                  animationOut="rotateOut"
                                  isVisible={display}
                                  className={'display_col jus-between ' + classes.item}
                                  style={{
                                      display:display?'flex':'none',
                                      backgroundColor: indexColor === 'none' ? 'white' : themes[indexColor].SubColor,
                                      border: indexColor === 'none' ? '1px solid #979797' : 'none'
                                  }}
                                  onClick={()=>this.onLink(item)}
                        >
                                <span style={{
                                        color: indexColor === 'none' ? '#707070' : themes[indexColor].MainColor
                                }}
                                >#{idx-this.indexHidden+1}</span>
                                <div className={'txt-two-line ' + classes.itemContent}>
                                    <b>{Config.lang('BI_Bao_cao') + '\n'}</b>
                                    {item.MReportName}
                                </div>
                                <div className={'btn-hover display_row align-center'}
                                     title={item.MReportName}
                                     onClick={()=>this.onLink(item)}
                                >
                                    <span>{Config.lang("BI_Xem")}</span>
                                </div>
                        </Animated>
                    )
                })}
                </div>
            </div>
        )
    }
}

export default compose(connect((state)=>({
        themes: state.main.themes,
        listGroupChart: state.W94F1000.listGroupChart,
    }),
    (dispatch) => ({
        W94F1000Actions: bindActionCreators(W94F1000Actions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch)
    })
),withStyles(styles, {withTheme: true}))(W94F1000);
