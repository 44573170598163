/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/7/2021
 * @Example
 */

import React, {useCallback} from 'react';
import PropTypes                     from "prop-types";
import moment                        from "moment";
import Config                        from "../../../../config";
import {makeStyles} from "diginet-core-ui/theme";

const isMobile = window.innerWidth <= 992;
const bg = isMobile ? require("../../../../assets/images/maintenance-bg-mobile.png") : require("../../../../assets/images/maintenance-bg.png");
const useStyles = makeStyles(theme => ({
    arrowThank: {
        width: 250,
        height: 50,
        background: "#FFFFFF",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 18,
        marginTop: 20,
        "&:before": {
            content: '" "',
            position: "absolute",
            right: -25,
            bottom: 0,
            width: 0,
            height: 0,
            borderLeft: "25px solid #ffffff",
            borderTop: "25px solid transparent",
            borderBottom: "25px solid transparent",
        },
        "&:after": {
            content: '" "',
            position: "absolute",
            left: 0, bottom: 0, width: 0, height: 0,
            borderLeft: "25px solid #e6f3ff",
            borderTop: "25px solid transparent",
            borderBottom: "25px solid transparent"
        },
        [theme.breakpoints.down('md')]: {
            display:'none'
        }
    },
    container:{
        margin: 0,
        height: "100vh",
        background: "#F1F8FF url("+ bg +") center no-repeat",
        width: "100%",
        backgroundPosition: "bottom right",
        backgroundSize: "100%",
        [theme.breakpoints.down('xs')]: {
            backgroundSize: "100%",
        }
    },
    content:{
        fontSize: 20,
        width: "50%",
        height: "100%",
        padding: 50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        top: -70,
        left: 30,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            top: 'initial',
            left: 'inherit',
            height: '300px',
            borderRadius: '10px',
            textAlign:'center'
        }
    }
}));
const keyFrom = "DateFrom";
const keyTo = "DateTo";
const Maintenance = (props) => {
    const {data} = props;
    const classes = useStyles();

    const renderTime = useCallback(() => {
        if (!data?.[keyFrom]) return null;
        const isSameDay = moment(data?.[keyFrom]).isSame(data?.[keyTo], "day");
        const from = isSameDay ? moment(data?.[keyFrom]).format("HH:mm") : moment(data?.[keyFrom]).format("HH:mm DD/MM/YYYY");
        let to = null;
        if (data?.[keyTo]) {
            to = isSameDay ? moment(data?.[keyTo]).format("HH:mm") : moment(data?.[keyTo]).format("HH:mm DD/MM/YYYY");
        }
        const time = from + (to ? " - " + to : "");
        return <>
            <div><b>{`${Config.lang("Thoi_gian")}:`}</b> {time}</div>
            {isSameDay && <div><b>{`${Config.lang("Ngay")}: `}</b>
                                    {moment(data?.[keyFrom]).format("DD/MM/YYYY")}</div>}
        </>
        /* eslint-disable */
    }, [data[keyFrom], data[keyTo]]);

    if (!data) return null;
    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <span style={{padding: 5}}>
                    <span style={{fontSize: 58, letterSpacing: 5, textTransform: "uppercase"}}>
                        {Config.lang("Thong_baoU") + "!"}</span><br/>
                    <span style={{fontStyle: "italic", letterSpacing: 2}}>
                        {Config.lang("He_thong_dang_trong_qua_trinh_nang_cap")}<br />
                        {Config.lang("Vui_long_truy_cap_sau")}
                    </span>
                </span>
                <div style={{marginTop: 15, fontSize: 16, padding: 10}}>
                    {renderTime()}
                </div>
                <div className={classes.arrowThank}>
                    {Config.lang("Xin_cam_on")}
                </div>
            </div>
        </div>
    );
};
Maintenance.propTypes = {
    /**
     * Accept object includes "from" and "to" (time)
     */
    data: PropTypes.object,
};

export default Maintenance;
