/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 */

import React from 'react';
import { Image, FormGroup, Row, Col} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as mainActions from "../../redux/main/main_actions";
import * as homeActions from "../../redux/home/home_actions";
import Config from "../../config";
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';
import {TextBox,Button as ButtonSM} from 'devextreme-react';
import ReactLoading from 'react-loading';
import {Checkbox, TextField} from "../common/form-material";
import Button from "@material-ui/core/Button";
import {MenuItem} from "@material-ui/core";
import LocalizedStrings from "react-localization";
import {localize}          from "../../localize/localize";
import moment from 'moment';

class LoginPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.txtUserID = null;
        this.timeBlock = 600000; //10 minutes

        this.setTextBox = (ref) => {
            if (ref) {
                this.txtUserID = ref.instance;
            }
        };

        this.state = {
            message: "",
            isSubmit: false,
            userID:"",
            passW:"",
            eUsernameFlag: null,
            ePasswordFlag: null,
            lang:'vi',
            saveAccount: false
        };
    };

    componentDidMount(){
        let lang = Config.getLocalStorage("langBI");
        let accountSaved = Config.getLocalStorage('LOGINBI');
        accountSaved = accountSaved && Config.isJson(accountSaved) ? JSON.parse(accountSaved) : null;
        if(accountSaved){
            this.setState({
                saveAccount: true,
                userID:accountSaved.user,
                passW:accountSaved.pass,
            });
            // this.userID = accountSaved.user;
            // this.passW = accountSaved.pass;
        }
        if (lang!==null && typeof lang !=='undefined'){

            this.setState({lang:lang});
        }
        this.txtUserID.focus();
    }

    onLogout() {
        if(this.props.onLogout) {
            this.props.onLogout();
        }
    };

    onKeyDown(e){
        if (this.state.userID!=='') {
            // this.setState({passW: e.event.target.value});
            switch (e.event.key) {
                case 'Enter':
                    this.onFormSubmit();
                    break;
                default:
                    break;
            }
        }
    }

    onKeyDownAccount(e){
        if (this.passW!==''){
            this.userID = e.event.target.value;
            switch (e.event.key) {
                case 'Enter':
                    this.onFormSubmit();
                    break;
                default:
                    break;
            }
        }
    }

    onEventSave() {
        const submit = document.getElementById("btnSubmit");
        submit.click();
    }

    handleLanguageChange = (e) => {
        if (e && e.target && e.target.value) {
            Config.setLocalStorage('langBI', e.target.value);
            this.loadLocalize();
            this.setState({lang: e.target.value});
        }
    };

    loadLocalize = () => {
        let cfLocalize = null;
        let lang = Config.getLocalStorage("langBI");

        if (!lang || lang.length !== 2){
            lang = "vi";
            Config.setLocalStorage('langBI','vi');
        }

        try {
            const lc = Config.getLocalStorage('LOCALIZE');
            cfLocalize = lc ? JSON.parse(lc) : null;

            if (!cfLocalize || cfLocalize.timestamp !== localize.timestamp) {
                cfLocalize = localize;
                Config.setLocalStorage('LOCALIZE',JSON.stringify(cfLocalize));
            }

        } catch (e) {

        }

        Config.localization = new LocalizedStrings(cfLocalize);
        Config.localization.setLanguage(lang);

        if (lang === "vi"){
            Config.language = "84";
        } else {
            Config.language = "01";
        }
    };

    handleSaveAccount = (e, value) => {
        this.setState({
            saveAccount: value
        });
    };
    getNestedChildren = (arr, parent) => {
        // console.log('== getNestedChildren => arr: ', arr);
        let out = [];
        for(let i in arr) {
            if(arr[i].NodeParent === parent) {
                let children = this.getNestedChildren(arr, arr[i].Node);

                if(children.length) {
                    arr[i].children = children;
                }
                out.push(arr[i]);
            }
        }
        return out;
    };

    onFormSubmit = () => {
        // if (!this.userID ||!this.passW ) return null;
        const {userID, passW} = this.state;
        this.setState({message: ""});
        if(!userID || !passW){
            this.setState({message: Config.lang("BI_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung")});
            return null;
        }

        // check User is login failed 3 times
        let blockListLogin = {};
        if(userID){
            blockListLogin = Config.getLocalStorage('BLOCK_LIST_LOGIN', true) || {};
            const time = blockListLogin[userID] ?  moment().valueOf() - blockListLogin[userID] : null;
            if(time && time < this.timeBlock){ //10 minutes
                this.setState({message: Config.lang(`Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%${moment(this.timeBlock - time).format(`m [${Config.lang('phut')}] ss ${Config.lang('Giay').toLowerCase()}`)}%_de_dang_nhap_lai`)});
                return null;
            }
            else if(blockListLogin[userID]){
               delete blockListLogin[userID];
               Config.setLocalStorage('BLOCK_LIST_LOGIN', JSON.stringify(blockListLogin));
            }
        }

        this.setState({isSubmit: true});
        this.props.mainActions.login({
            username: userID,
            password: passW,
            language: Config.language || "84"
        }, (error, data) => {
            if(error){
                let errorCode = error.code || null;
                let message = "";

                switch (errorCode) {
                    case "US004":
                        message =  Config.lang("BI_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    case "US029":
                        message = Config.lang("BI_Tai_khoan_khong_co_quyen");
                        break;
                    case "US033":
                        message = Config.lang("BI_Tai_khoan_bi_khoa_10_phut");
                        break;
                    case "US038":
                        message = Config.lang(`Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%10 ${Config.lang('phut')}%_de_dang_nhap_lai`);

                        // save User to list user is login failed 3 times
                        const blockListLogin = Config.getLocalStorage('BLOCK_LIST_LOGIN', true) || {};
                        blockListLogin[userID] = moment().valueOf();
                        Config.setLocalStorage('BLOCK_LIST_LOGIN', JSON.stringify(blockListLogin));

                        break;
                    default:
                        message = error.message ||Config.lang("BI_Loi_chua_xac_dinh");
                        break;
                }

                this.setState({isSubmit: false, message: message});
                return false;
            }
            else if(data){
                let user = data.user || {};
                const {saveAccount} = this.state;
                let lang = Config.getLocalStorage("langBI");


                if (!lang || lang.length !== 2){
                    lang = "vi";
                    Config.setLocalStorage('langBI', lang);
                }

                if (saveAccount) {
                    const login = {user: userID, pass: passW};
                    Config.setLocalStorage("LOGINBI", JSON.stringify(login));
                } else {
                    Config.removeLocalStorage("LOGINBI");
                }

                const expire = new Date().getTime() + 7*24*60*60*1000;

                Config.token = {
                    id: data.token,
                    expire: expire
                };
                user.type = 2;

                Config.setLocalStorage('PROFILEBI', JSON.stringify(user));
                Config.setLocalStorage('TOKENBI', JSON.stringify(Config.token));
                Config.setLocalStorage('BI_VIEW', "grid");

                this.props.homeActions.getMenu({language:Config.language},(errGetMenu, dataGetMenu)=>{
                    if (errGetMenu){
                        Config.popup.show('INFO', errGetMenu.message);
                        return false;
                    } else if (dataGetMenu){
                        Config.setLocalStorage('MENUBI', JSON.stringify(dataGetMenu));
                        this.setState({isSubmit: false, message: ''});
                        window.location.reload();
                    }
                });
            }
        });
    };

    onTextValueChanged(e) {
        switch (e.event.target.name) {
            case "UserID":
                this.setState({userID: e.event.target.value});
                break;
            case "PassW":
                this.setState({passW: e.event.target.value});
                break;
            default:
                break;
        }
    }

    render(){
        const {lang, saveAccount} = this.state;
        const logoImg = require('../../assets/images/general/logobi.svg');
        const logoImgCustomer = Config.getSetting('LOGO_URL') ? Config.getSetting('LOGO_URL') : require('../../assets/images/general/logobi.svg');
        return(
            <div className="login-container page-login">
                <div className="login-bound">
                    <div className="display_row align-between" style={{width: '100%'}}>
                        <div className={'display_col align-between'} style={{width: '100%'}}>
                            <div className="header form-group">
                                <span>{Config.lang("BI_Dang_nhap")}</span>
                                <Image className="logo" src={logoImg} />
                            </div>
                            <div className="login-form">
                                <FormGroup>
                                    <label htmlFor={"username"} className={'login-label'}> {Config.lang('BI_Ten_dang_nhap')} </label>
                                    <TextBox id={"username"} ref={this.setTextBox}
                                             name={"UserID"}
                                             value={this.state.userID}
                                             onChange={(e) => this.onTextValueChanged(e)}
                                             onKeyDown={(e)=>{this.onKeyDownAccount(e)}}
                                             className="input-devextreme input-name"
                                             maxLength={200}
                                             showClearButton={false}
                                             placeholder={Config.lang('BI_Ten_dang_nhap')}
                                    >
                                        <Validator >
                                            <RequiredRule message="Giá trị bắt buộc nhập"/>
                                        </Validator>
                                    </TextBox>
                                </FormGroup>
                                <FormGroup className={"mgb15"}>
                                    <label htmlFor={"password"} className={'login-label'}> {Config.lang('BI_Mat_khau')} </label>
                                    <TextBox id={"password"} name={"PassW"}
                                             value={this.state.passW}
                                             onChange={(e) => this.onTextValueChanged(e)}
                                             onKeyDown={(e)=>{this.onKeyDown(e)}}
                                             className="input-devextreme input-pass"
                                             maxLength={200}
                                             showClearButton={false}
                                             mode={'password'}
                                             placeholder={Config.lang('BI_Mat_khau')}
                                    >
                                    </TextBox>
                                    <Checkbox
                                        style={{
                                            marginTop: 10,
                                            marginLeft: -12,
                                            color: '#9C9C9C'
                                        }}
                                        checked={saveAccount}
                                        label={Config.lang("BI_Nho_mat_khau1")}
                                        color={'primary'}
                                        onChange={this.handleSaveAccount}
                                        />
                                </FormGroup>
                                <div className="error">{this.state.message}</div>
                                <div className="display_row valign-middle" style={{width: '100%', padding: '0 20px', marginBottom: 54}}>
                                    <Button disabled={this.state.isSubmit} className="login-bt" onClick={()=>{this.onEventSave()}}>
                                        {this.state.isSubmit ? <div className="display_row align-center valign-middle" style={{
                                            position: 'absolute',
                                            width: '100%',
                                            left: 0,
                                            top: 0,
                                            height: '100%',
                                        }}>
                                            <ReactLoading type={"spinningBubbles"} color={'white'} height={17} width={17}/>
                                        </div> : Config.lang("BI_Dang_nhap")}
                                    </Button>
                                </div>
                                <ButtonSM id="btnSubmit" useSubmitBehavior={true} onClick={(e) => this.onFormSubmit(e)} className="hide"/>
                                <div className="display_row align-between" style={{width: '100%'}}>
                                    <div className={'span-language'}>
                                        <Image src={require('../../assets/images/internet.svg')} style={{marginRight: 5}} />
                                        <TextField
                                            select
                                            variant={"standard"}
                                            margin={"dense"}
                                            value={lang}
                                            placeholder={Config.lang('BI_Ngon_ngu')}
                                            onChange={this.handleLanguageChange}
                                        >
                                            <MenuItem value={'en'}>{"English"}</MenuItem>
                                            <MenuItem value={'vi'}>{"Tiếng Việt"}</MenuItem>
                                        </TextField>
                                    </div>
                                    <div className={"span-power"}>{Config.lang("Phien_ban")}: {Config.getSetting('APP_VERSION')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="display_row align-center valign-middle login-company-logo">
                            <div className="display_row align-center valign-middle panel-logo">
                                {Config.getSetting("LOGO_URL") && <Image src={logoImgCustomer} style={{ width: "100%"}} />}
                                {!Config.getSetting("LOGO_URL") && (
                                    <React.Fragment>
                                        <Image src={logoImgCustomer} style={{width: '35%'}}/>
                                        <span style={{marginLeft: 5, fontSize: '2.125rem', fontWeight: 500}}>
                                            <b>LEMON3</b>
                                            <span style={{fontWeight: 200}}>-BI</span>
                                        </span>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'login-wrapper-footer'}>
                    <Row className={'login-footer'}>
                        <Col sm={6} md={6} xs={12}>
                            <span>DIGINET CORPORATION</span>
                            <label>© Copyright 2020 DigiNet Corporation.</label>
                        </Col>
                        <Col sm={6} md={6} xs={12}>
                            <a rel="noopener noreferrer" target={'_blank'} href={'www.diginet.com.vn'}>www.diginet.com.vn</a>
                            <Image src={require('../../assets/images/logo.png')}/>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default connect(state => ({

    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch),
        homeActions: bindActionCreators(homeActions, dispatch)
    })
)(LoginPage);
