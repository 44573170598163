/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/08/12 10:37
 * @update 2019/08/12 10:37
 * @file src/components/common/sort.js
 * @example buttonCRM: FormW18F1016
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DataGrid, {Paging, Scrolling, Selection} from "devextreme-react/data-grid";
import TitleGrid from "../libs/label/title-grid";
import PagingCrm from "../libs/paging/paging-crm";
import GridToolbar from "../tool-bar/grid-toolbar";
import GridFilterBar from "../tool-bar/grid-filter";

class GridContainer extends Component {

    constructor(props) {
        super(props);
        const listPerPage = props.listPerPage ? props.listPerPage : [10, 20, 30, 50];
        this.state = {
            currentPage: props.isPage || props.isPage === 0 ? props.isPage : 1,
            itemPerPage: props.itemPerPage ? props.itemPerPage : listPerPage[0],
            allowedPageSizes: listPerPage,
            selectKey: [],
            deselectKey: [],
            selectRowData: [],
        };
        this.isSubmit = false;
    }

    cellClick(e) {
        // Bi loi khi chay cung voi rowclick, chua fix
        if (!this.props.onCellClick && !this.props.onDbCellClick) return false;
        let component = e.component;
        let prevClickTime = component.lastClickTime;
        component.lastClickTime = new Date();
        if (prevClickTime && (component.lastClickTime - prevClickTime < 300) && this.props.onDbCellClick) {
            this.props.onDbCellClick(e);
        } else if (this.props.onCellClick) {
            this.props.onCellClick(e);
        }
    }

    rowClick(e) {

        if (!this.props.onDbRowClick && !this.props.onRowClick) return false;
        let component = e.component;
        let prevClickTime = component.lastClickTime;
        component.lastClickTime = new Date();
        if (prevClickTime && (component.lastClickTime - prevClickTime < 300) && this.props.onDbRowClick) {
            this.props.onDbRowClick(e);
        } else if (this.props.onRowClick) {
            this.props.onRowClick(e);
        }
    }

    selectRow(e) {
        //Khong can phai khai bao keyID
        const selectedRowData = e.selectedRowsData; //array chứa những dòng selected
        let deselectedKey = e.currentDeselectedRowKeys; //array chứa những dòng unselected
        let selectVal = [...this.state.selectKey]; //array chứa key
        let selectRow = [...this.state.selectRowData];// array chua rowData
        let i;
        let gridContain = [];// array chứa những dòng trong datasource === deselectedKey
        const keyExpr = e.component.option('keyExpr');
        const gridData = e.component.option('dataSource');

        //Quét for array selectedKey ,nếu ko có trong array selectVal thì add vào
        for (i = 0; i < selectedRowData.length; i++) {
            let j = 0;
            let isContinue = false;
            for (j = 0; j < selectVal.length; j++) {
                if ((keyExpr ? selectVal[j] : JSON.stringify(selectVal[j])) === (keyExpr ? selectedRowData[i][keyExpr] : JSON.stringify(selectedRowData[i]))) {
                    isContinue = true;
                    break;
                }
            }
            if (isContinue) continue;
            selectVal.push(keyExpr ? selectedRowData[i][keyExpr] : selectedRowData[i]);
            selectRow.push(selectedRowData[i]);
        }
        //Nếu change page hay search thì ko delete giá trị  theo array deselectedKey
        if (deselectedKey.length > 0) { //Chỉ delete khi datasouce có chứa giá trị
            for (i = 0; i < deselectedKey.length; i++) {
                for (let j = 0; j < gridData.length; j++) {
                    if ((keyExpr ? deselectedKey[i] : JSON.stringify(deselectedKey[i])) === (keyExpr ? gridData[j][keyExpr] : JSON.stringify(gridData[j]))) {
                        gridContain.push((keyExpr ? gridData[j][keyExpr] : gridData[j]))
                    }
                }
            }
            if (JSON.stringify(gridContain.sort()) === JSON.stringify(deselectedKey.sort())) {
                for (i = deselectedKey.length - 1; i >= 0; i--) {
                    let j = 0;
                    for (j = selectVal.length - 1; j >= 0; j--) {
                        if ((keyExpr ? selectVal[j] : JSON.stringify(selectVal[j])) === (keyExpr ? deselectedKey[i] : JSON.stringify(deselectedKey[i]))) {
                            selectVal.splice(j, 1);
                            selectRow.splice(j, 1);
                        }
                    }
                }
            }
        }
        this.setState({
            selectKey: [...selectVal],
            selectRowData: [...selectRow]
        }, () => {
            const {selectRowData} = this.state;
            const {onSelectionChanged} = this.props;

            if (onSelectionChanged) {
                onSelectionChanged(e, selectRowData)
            }
        });

    }

    componentWillUnmount() {
        this.setState({
            selectKey: [],
        })
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.itemPerPage) !== JSON.stringify(this.state.itemPerPage) || JSON.stringify(nextProps.isPage) !== JSON.stringify(this.state.isPage)) {
            this.setState({
                itemPerPage: nextProps.itemPerPage,
                currentPage: nextProps.isPage
            })
        }
    }

    handleScroll = (e) => {
        if (this.isSubmit) return;
        const bottom = e.target.scrollTop && (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight);
        if (bottom && this.props.loadMore) {
            this.isSubmit = true;
            this.props.loadMore(() => {
                this.isSubmit = false;
            });
        }
    };

    setModeSelection = () => {
        // truyền trực tiếp select={object} vào grid container sẽ làm ảnh hướng input bị chậm và chrome warning, set bằng component vào children thì không sao
        const {selection} = this.props;

        if (!selection) {
            return <Selection mode={'single'}/>;
        }
        return <Selection {...selection}/>;
    };

    clearSelectRow = () => {
        //fix tạm deadline clear các dòng dc check, khi load lại lưới
        this.setState({
            selectRowData:[],
            selectKey: []
        })
    };

    render() {
        let {
            dataSource, title, totalItems, skipPerPage, keyExpr, onSearch, onFilter, filterData,
            buttonCRM, selectedRowKey, typeShort, wordWrapEnabled, editing, columnResizingMode, filterRow,
            columnAutoWidth, style, allowSearch, enabledPage, loadMore, widthFull, disabled,
            isPer, allowAdding, onAddNew, showSendMail, onSendMail, reference, id, onRowInserted,
            onRowUpdated, onRowRemoved, onRowUpdating, onEditingStart, onInitNewRow, onRowRemoving,
            onEditorPrepared, onEditorPreparing, children, onChangePage, onChangePerPage, valueSearch,
            showAdvancedSearch, onAdvancedSearch, onClearAdvanced, formID, disabledAddNew
        } = this.props;
        const {
            currentPage, itemPerPage, allowedPageSizes, selectKey
        } = this.state;

        //selectedRowKeys của thay đổi thuộc tính của object state.selectkey nên phải deep copy object này
        let selectKeyF = selectKey.length ? typeof (selectKey[0]) === 'object' ? selectKey.map(a => ({...a})) : selectKey : [];
        // this.state.selectkey trả ra 2 dạng array thường và array object, là array object thì deep copy
        totalItems = parseInt(totalItems, 10);
        return (
            <div className="grid-container" style={style ? style : {}}
                 onScroll={loadMore ? this.handleScroll : null}>
                {title && <TitleGrid label={title}/>}

                {onSearch &&
                <GridToolbar disabled={disabled}
                             formID={formID}
                             onAdvancedSearch={onAdvancedSearch}
                             showAdvancedSearch={showAdvancedSearch}
                             onClearAdvanced={onClearAdvanced}
                             allowAdding={allowAdding}
                             allowSearch={allowSearch}
                             isPer={isPer}
                             buttonCRM={buttonCRM && buttonCRM}
                             valueSearch={valueSearch && valueSearch}
                             onSearch={(text) => {
                                 onSearch && onSearch(text);
                             }}
                             onAddNew={onAddNew}
                             disabledAddNew={disabledAddNew}
                             showSendMail={showSendMail}
                             onSendMail={onSendMail}/>}

                {onFilter && filterData &&
                <GridFilterBar onFilter={onFilter} filterData={filterData}/>}
                <DataGrid
                    disabled={disabled ? disabled : false}
                    id={id ? id : null}
                    ref={ref => reference && reference(ref)}
                    dataSource={dataSource}
                    keyExpr={keyExpr}
                    showBorders={true}
                    editing={editing && editing}
                    filterRow={filterRow && filterRow}
                    width={widthFull ? 'fit-content' : "100%"}
                    showColumnLines={true}
                    wordWrapEnabled={wordWrapEnabled ? wordWrapEnabled : false}
                    showRowLines={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    columnAutoWidth={columnAutoWidth ? columnAutoWidth : true}
                    columnResizingMode={columnResizingMode ? columnResizingMode : 'nextColumn'}
                    onSelectionChanged={(e) => {
                        this.selectRow(e)
                    }}
                    onRowInserted={(e) => {
                        if (onRowInserted) onRowInserted(e);
                    }}
                    onRowUpdated={(e) => {
                        if (onRowUpdated) onRowUpdated(e);
                    }}
                    onRowRemoved={(e) => {
                        if (onRowRemoved) onRowRemoved(e);
                    }}
                    onRowUpdating={(e) => {
                        if (onRowUpdating) onRowUpdating(e);
                    }}
                    onEditingStart={(e) => {
                        if (onEditingStart) onEditingStart(e);
                    }}
                    onInitNewRow={(e) => {
                        if (onInitNewRow) onInitNewRow(e);
                    }}
                    onRowRemoving={(e) => {
                        if (onRowRemoving) onRowRemoving(e);
                    }}
                    onEditorPrepared={(e) => {
                        if (onEditorPrepared) onEditorPrepared(e);
                    }}
                    onEditorPreparing={(e) => {
                        if (onEditorPreparing) onEditorPreparing(e);
                    }}
                    rowAlternationEnabled={true}
                    onCellClick={(e) => {
                        this.cellClick(e);
                    }}
                    onRowClick={
                        (e) => {
                            this.rowClick(e);
                        }}
                    selectedRowKeys={selectedRowKey ? selectedRowKey : selectKeyF}
                >
                    {this.setModeSelection()}
                    <Scrolling columnRenderingMode={'virtual'}/>
                    {children}
                    <Paging defaultPageSize={100} enabled={enabledPage !== 'disabled'}/>
                </DataGrid>

                {totalItems > 0 && <PagingCrm
                    typeShort={typeShort ? typeShort : false}
                    totalItems={totalItems}
                    skipPerPage={skipPerPage}
                    currentPage={currentPage}
                    itemPerPage={itemPerPage}
                    listPerPage={allowedPageSizes}
                    onChangePage={(page) => {
                        if (onChangePage) onChangePage(page);
                    }}
                    onChangePerPage={(per) => {
                        if (onChangePerPage) onChangePerPage(per);
                    }}
                />}
            </div>
        );
    }
}

GridContainer.propTypes = {
    selectedRowKey: PropTypes.any,
    title: PropTypes.string,
    type: PropTypes.string,
    field: PropTypes.string,
    itemPerPage: PropTypes.number,
    totalItems: PropTypes.number,
    listPerPage: PropTypes.any,
    dataSource: PropTypes.any,
    keyExpr: PropTypes.string,
    showSendMail: PropTypes.bool,
    selection: PropTypes.object,
    buttonCRM: PropTypes.array,
    isPer: PropTypes.number,
    isPage: PropTypes.number,
    allowAdding: PropTypes.number,
    allowSearch: PropTypes.string,
    typeShort: PropTypes.bool,
    wordWrapEnabled: PropTypes.bool,
    editing: PropTypes.object,
    filterRow: PropTypes.object,
    columnResizingMode: PropTypes.string,
    columnAutoWidth: PropTypes.bool,
    reference: PropTypes.func,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    valueSearch: PropTypes.string,
    showAdvancedSearch: PropTypes.bool,
    disabledAddNew: PropTypes.bool,
    formID: PropTypes.string,

    onFilter: PropTypes.func,
    onAddNew: PropTypes.func,
    onSearch: PropTypes.func,
    onChangePage: PropTypes.func,
    onRowClick: PropTypes.func,
    onDbRowClick: PropTypes.func,
    onDbCellClick: PropTypes.func,
    onCellClick: PropTypes.func,
    onChangePerPage: PropTypes.func,
    customizeColumns: PropTypes.func,
    onSendMail: PropTypes.func,
    onRowInserted: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    onRowUpdated: PropTypes.func,
    onRowRemoved: PropTypes.func,
    onRowUpdating: PropTypes.func,
    onEditingStart: PropTypes.func,
    onInitNewRow: PropTypes.func,
    onRowRemoving: PropTypes.func,
    onEditorPrepared: PropTypes.func,
    onEditorPreparing: PropTypes.func,
    onAdvancedSearch: PropTypes.func,
    onClearAdvanced: PropTypes.func,
};

export default GridContainer;