/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/04/24 18:12
 * @update 2019/04/24 18:12
 */

import {locale, loadMessages} from 'devextreme/localization';
import * as Helpers from '../components/common/helpers';
import {DataSource} from "devextreme/data/data_source/data_source";
import {isMobile} from "react-device-detect";
import {browserHistory} from "react-router";
import moment from "moment";
const env = require('./environment');

class Config {

    //////////////////////////
    //Use localStorage..
    static getKeyEnv = (key = "") => {
        const prefixLang = Config?.env?.prefixLang || "";
        let keyEnv = Config.env.keyEnv && Config.env.keyEnv !== "NO" ? Config.env.keyEnv : "";
        keyEnv = keyEnv.trim() + prefixLang.trim();
        return keyEnv.trim() + key;
    };
    static setLocalStorage = (key, value) => {
        if (!key) return false;
        key = Config?.getKeyEnv(key);
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            return false;
        }
    };
    static getLocalStorage = (key, isJSONParse = false, isNewKey = false) => {
        if (!key) return null;
        const newKey = Config?.getKeyEnv(key);
        try {
            let item = localStorage.getItem(newKey);
            if (!isNewKey && !item) {
                item = localStorage.getItem(key);
            }
            if (this.isJson(item) && isJSONParse) item = JSON.parse(item);
            return item;
        } catch (e) {
            return null;
        }
    };
    static removeLocalStorage = (keys) => {
        if (!keys || keys.length <= 0) return false;
        const keyEnv = Config?.getKeyEnv();
        try {
            if (Array.isArray(keys)) {
                for (let key of keys) {
                    const k = keyEnv ? keyEnv.trim() + key : key;
                    localStorage.removeItem(k);
                }
            } else {
                keys = keyEnv ? keyEnv.trim() + keys : keys;
                localStorage.removeItem(keys);
            }
            return true;
        } catch (e) {
            return false;
        }
    };
    //Use localStorage..
    ////////////////////////////////////////
    static env = env;
    static token = {};
    static tokenCDN = {};
    static isMobile = isMobile;
    static color = {};
    static themes = [];
    static profile = {};
    static setting = [];
    static getToken = false;
    static filters = null;
    static formInfo = null;
    static formID = "";
    static popup = null;
    static debugger = null;
    static notify = null;
    static viewType = 0;
    static language = null;
    static perPage = 20;
    static database = '';
    static helpers = Helpers;
    static controller = null;
    static popupTransition = Helpers.popupTransitions.Slide;
    static permission = {
        'USER': 1,
        'ADMIN': 2,
        'SUPPORTER': 3,
        'BOT': 4
    };
    static menuType = Number(Config.getLocalStorage("MENUTYPEBI")); //default MSS...
    static localization = null;
    static URlDef = require('../assets/images/icon-user-default.png');
    static listUsers = [];
    static encryptKey = "DHR@012345678#keyEncrypt";
    static coreTheme = null;
    static deployer = process?.env?.REACT_APP_PASSWORD_DEPLOYER || '@12345@';

    static getHREmployeeID = () => {
        let result = '';
        if (this.profile.HREmployeeID) {
            result = this.profile.HREmployeeID;
        }
        return result
    };

    static getDivisionID = () => {
        let result = '';
        if (this.formInfo.HRDivisionID) {
            result = this.formInfo.HRDivisionID;
        }
        return result;
    };

    static getHRTransMonth = () => {
        let result = '';
        if (this.formInfo.HRTranMonth) {
            result = this.formInfo.HRTranMonth;
        }
        return result;
    };

    static getHRTransYear = () => {
        let result = '';
        if (this.formInfo.HRTranYear) {
            result = this.formInfo.HRTranYear;
        }
        return result;
    };

    static getCreatorHR = () => {
        let result = '';
        if (this.formInfo.CreatorHR) {
            result = this.formInfo.CreatorHR;
        }
        return result
    };

    static getDatabaseName = () => {
        return Config.getSetting('DB_BI_NAME') ? Config.getSetting('DB_BI_NAME') : "";
    };

    static getLocale = () => {
        let result = "vi";
        if (Config.getLocalStorage('langBI')) {
            result = Config.getLocalStorage('langBI');
        }
        return result;
    };

    static getThemes = (key) => {
        const theme = {
            'primary': {
                light: '#7598F724',
                main: '#7598F7',
                contrastText: '#fafafa'
            },
            'secondary': {
                light: '#f5005730',
                main: '#f50057',
                contrastText: '#ffffff'
            },
            'pink': {
                light: '#F794FF30',
                main: '#F794FF',
                contrastText: '#063E6F'
            },
            'green': {
                light: '#00944930',
                main: '#009449',
                contrastText: '#fafafa'
            },
            'blue': {
                light: '#0081CB30',
                main: '#0081CB',
                contrastText: '#fafafa'
            },
            'dark': {
                light: '#00000030',
                main: '#000000',
                contrastText: '#ffffff'
            }
        };
        if (key) return theme[key] ? theme[key] : theme.primary;
        return theme;
    };

    /**
     * Get system setting
     * Example:  getSetting('BI_NAME') => 'DRD02V41'
     * @param name
     * @returns {*}
     */
    static getSetting = (name) => {
        if (Config.setting) {
            let setting = Config.setting.filter(s => s.name === name);

            if (setting && setting[0] && setting[0].name) {
                switch (setting[0].type) {
                    case "NUMBER":
                        return parseFloat(setting[0].value);
                    case "JSON":
                        return JSON.parse(setting[0].value);
                    default:
                        return setting[0].value;
                }
            }
        }

        return null;
    };

    static logout = () => {
        Config.removeLocalStorage('TOKENBI');
        Config.removeLocalStorage('PROFILEBI');
        Config.removeLocalStorage('SETTINGBI');
        Config.removeLocalStorage('FORMINFOBI');
        Config.removeLocalStorage('BI_VIEW');
        // Config.removeLocalStorage('langBI');
        // Config.removeLocalStorage('breadcrumbHR');
        Config.removeLocalStorage('tokenCDN');

        window.location.href = Config.getRootPath();
    };

    /**
     * Replace special symbol,using for control as textbox Code ,ID .v.v.
     * Ex: replaceSymbol('*Khoái') => 'Khoai'
     * @param value
     * @returns {string}
     */
    static replaceSymbol = (value) => {
        // let str = str.replace(/[|?~=",{}[\];^%']/gi, '');
        let str = value.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(/\s/g, '');
        str = str.replace(/[^0-9a-z_\-#*/(\\)]/gi, '');

        return str.toUpperCase();
    };

    /**
     * Convert date by special format
     * @param value
     * @param defaultValue
     * @param format
     * @returns {string}
     */
    static convertDate = (value, defaultValue, format) => {
        if (!value) return defaultValue ? defaultValue : null;
        return moment(value).format(format ? format : "DD/MM/YYYY");
    };

    /**
     * Using translate resource of project (caption,title,description,label,text.v.v.)
     * Example : lang(Dang_nhap) => vi:Đăng nhập  || en :Log In
     * @param text
     * @returns {*}
     */
    static getKeyLocalize = (text) => {
        if (!Config.localization || !text) return "";
        const prefix = this.env.prefixLang || "BI_";
        if (text && text.includes(prefix)) text = text.replace(prefix, '');
        return Object.keys(Config.localization).find(l => {
            if (l && l.includes(prefix)) l = l.replace(prefix, '');
            return l.toLowerCase() === text.toLowerCase();
        });
    };
    static lang = (text, mode) => {
        let str;
        if (Config.localization !== null) {
            let keyTmp = text;
            if (text && text.match(/%(.*?)%/g)) {
                keyTmp = text.replace(/%(.*?)%/g, "%p"); //define key param, require is %p%.
            }
            let key = this.getKeyLocalize(keyTmp);
            if(mode){
                const lc =Config.getLocalStorage('LOCALIZE');
                const lang = JSON.parse(lc);
                str = lang[mode][key];

            } else {
                // str = Config.localization[text] ? Config.localization[text] : Config.localization[text1];
                str = Config.localization[key];
            }
            if (typeof str !== "undefined") {
                const match = text.match(/%(.*?)%/i);
                const match2 = text.match(/%(.*?)%/g);
                if (match) {
                    match2.forEach(m => {
                        m = m.slice(1, -1);
                        str = str.replace("%p", m);
                    });
                }
                return str;
            }
        }
        return text;
    };

    /**
     * Set language devextreme component
     * @returns {void}
     */
    static setLangDevextreme() {
        const lang = Config.getLocalStorage('langBI');
        let dataLang = Config.getLocalStorage('LOADDEVEXTREME');
        if(dataLang && this.isJson(dataLang)){
            dataLang = JSON.parse(dataLang);
            loadMessages(dataLang);
            locale(lang);
        }
        if (lang === 'vi' && window.dxMessage) {
            Config.setLocalStorage('LOADDEVEXTREME', JSON.stringify(window.dxMessage));
            loadMessages(window.dxMessage);
            locale('vi');
        }
        else if(lang === 'en'){
            locale('en');
        }
    }

    static randomSRC (width, height){
        const widthImage = width ? width : 320;
        const heightImage = height ? height : 320;
        return "https://loremflickr.com/"+widthImage+"/"+heightImage;
    };

    static getRootPath() {
        return (process && process.env && process.env.REACT_APP_ROOT) ? process.env.REACT_APP_ROOT : '/';
    }

    static isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    static storeDataSoureDevExtreme(dataSource, limit) {
        if (dataSource.length <= 0) return [];
        return new DataSource({
            store: dataSource,
            paginate: true,
            pageSize: limit ? limit : 10
        });
    }

    /**
     * Save state to location with browserHistory.
     * @param component
     * @param param = {}
     */
    static setStoreState(component, param) {
        const route = component.props.route.path;

        browserHistory.push({
            pathname: Config.getRootPath() + route,
            state: param
        });
    }

    /**
     * Get state from location and set state of component with browserHistory.
     * @param component
     * @param cb callback
     * @returns null
     */
    static getStoreState(component, cb) {
        const route = component.props.route.path;
        const data = component.props.location && component.props.location.state ? component.props.location.state : null;
        const state = {...data, storeFilter: true};

        if (data) {
            component.setState(state, () => {
                cb && cb();
            });
            browserHistory.push({
                pathname: Config.getRootPath() + route,
            });
        }
        return data;
    }

}

export default Config;
