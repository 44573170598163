/**
 * @copyright 2019 @ DigiNet
 * @author TRANGHOANG
 * @create 1/21/2020
 */
import React from "react";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import Config from "../../../../config";
import {browserHistory} from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as W94F1000Actions from "../../../../redux/W9X/W94F1000/W94F1000_actions";
const styles = theme => {
    return {
        btnPrint:{
            [theme.breakpoints.down(768)]: {
                '& span>.mgl5':{
                    display:'none'
                }
            },
        }
    }
};

class W94F1001 extends React.Component {

    constructor(props){
        super(props);
        this.state={
            detailChart: null
        }
    }

    getInfo = (flag) => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('MReportID')) {
            return {MReportID: url.get('MReportID')};
        } else if (location && location.state) {
            return {
                MReportID: location.state.MReportID
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + 'W94F1000');
                return null;
            }
            return false;
        }
    };

    componentWillUnmount() {
        if(this.tempWindow) this.tempWindow.close();
    }

    componentDidMount = () => {
        Config.filters.renderHTML(()=>{return null;});
        if (!this.getInfo(true)) return null;
        const {MReportID} = this.getInfo();
        const {listGroupChart} = this.props;
        if(!listGroupChart){
            this.props.W94F1000Actions.getDetail({MReportID: MReportID}, (err, data)=>{
                if(data && data.length > 0){
                    this.setState({
                        detailChart: data[0]
                    },()=>{
                        Config.filters.renderHTML(()=>this.renderHeader(data[0]));
                    });
                }
                else{
                    Config.popup.show('INFO', err.message);
                }
            });
        }
        else if(listGroupChart && listGroupChart.Detail){
            const item = listGroupChart.Detail.find(i=>i.MReportID === MReportID);
            if(item){
                Config.filters.renderHTML(()=>this.renderHeader(item));
                this.setState({
                    detailChart: item
                })
            }
            else{
                Config.popup.show('INFO',Config.lang('BI_Khong_co_du_lieu'))
            }
        }
    };

    renderHeader = (detailChart) => {
        const {classes} = this.props;
        return(
            <ActionToolbar
                title={detailChart && detailChart.MReportName}
                classTitle={'txt-two-line'}
                styleTitle={{
                    fontSize: '1.2rem',
                    fontWeight:'bold',
                    overflow:'inherit'
                }}
                alignment={'flex-end'}
                showBorder={false}
                style={{
                    marginTop:0,
                    flexWrap:'nowrap'
                }}
                allwaysTop={false}
            >
                <ButtonGeneral typeButton={'excel'}
                               name={Config.lang('BI_Xuat_bao_cao')}
                               color={"primary"}
                               className={classes.btnPrint}
                               variant={"contained"}
                               style={{textTransform: 'uppercase', padding: '8px 15px'}}
                               size={"large"}
                               onClick={()=>this.onPrint(detailChart)}
                />
            </ActionToolbar>
        )
    };

    onPrint = (detailChart) => {
        if(!detailChart) return;

        //create new window with function print
        this.tempWindow = window.open('', 'Print', 'width=800,height=930');
        const title = document.createElement('h1');
        if(detailChart.MReportName){
            title.innerHTML=detailChart.MReportName;
            title.style="width:100%; height: 40px; display:flex; align-item:center; justify-content: center; font-size: 20; padding: 10px;";
            this.tempWindow.document.body.appendChild(title);
        }
        //copy irame chart powerbi
        const newIframe = this.iframeView.cloneNode(true);
        newIframe.style="width: calc(21cm - 20px); height: calc(24.5cm - 20px);";
        this.tempWindow.document.body.appendChild(newIframe);
        newIframe.onload = () => {
            //button print
            const btn = document.createElement('button');
            btn.innerHTML="In báo cáo";
            btn.style="margin-right: 10px; padding: 10px; position:absolute; right:60px";
            btn.onclick=()=>{
                btn.style.opacity="0";
                btnClose.style.opacity="0";
                this.tempWindow.focus();
                this.tempWindow.print();
                setTimeout(()=>{
                    btn.style.opacity="1";
                    btnClose.style.opacity="1";
                },200)
            };
            //button close popup
            const btnClose = document.createElement('button');
            btnClose.innerHTML="Đóng";
            btnClose.style="margin-right: 10px; padding: 10px; position:absolute; right:0px";
            btnClose.onclose=()=>{
                btn.style.opacity="1";
                btnClose.style.opacity="1";
            };
            btnClose.onclick=()=>{
                this.tempWindow.close();
            };
            //add button to window
            this.tempWindow.document.body.prepend(btn);
            this.tempWindow.document.body.prepend(btnClose);
        }
    };

    render() {
        if (!this.getInfo(true)) return null;
        const {detailChart} = this.state;
        if(!detailChart) return null;
        return (
            <div style={{height:'100%'}}>
                <iframe ref={ref=>this.iframeView=ref}
                        style={{
                            maxWidth: '100%',
                            maxHeight: 'calc(100% - 30px)',
                            width: '100%',
                            height: '100%'
                        }}
                        title={'My chart'}
                        frameBorder={'none'}
                        src={detailChart && detailChart.EmbedCode}
                        allowFullScreen={true}
                />
            </div>
        )
    }
}

export default compose(connect((state)=>({
        listGroupChart: state.W94F1000.listGroupChart,
    }),
    (dispatch) => ({
        W94F1000Actions: bindActionCreators(W94F1000Actions, dispatch),
    })
),withStyles(styles, {withTheme: true}))(W94F1001);
