/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/20/2019
 * @updated QUOCUY|13/09/2021
 * @Example
 */

import React, { PureComponent } from 'react';

import PropTypes from "prop-types";
import { Typography, ButtonIcon } from "diginet-core-ui/components";
import Config from '../../../config/index';
import {makeStyles} from "diginet-core-ui/theme";

const useStyles = makeStyles({
    root: {
        position: "relative",
        padding: "5px 0",
        display: "flex",
        width: '100%',
        flex: 1
    },
    title: {
        textTransform: 'uppercase'
    },
    onTop: {
        position: 'fixed',
        zIndex: 240,
        backgroundColor: 'white',
    },
    onTopDiv: {
        marginTop: '-15px',
    },
    fullWidth: {
        width: '100%',
    }
});
class ActionToolbar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            customHeight: 0,
            inPopup: false
        };
    }

    componentDidMount() {
    }

    /**
     * Get list parent of Action Toolbar, list Parent has className is modal|tooltip|popup|popover => is inside modal|tooltip|popup|popover
     */
    isPopup = (el) => {
        let a = el;

        // classname-aware string
        const className = /modal|tooltip|popup|popover|dialog/;

        do{
            a = a.parentNode;
            // check action toolbar in modal|tooltip|popup
            if(a.className.toLowerCase().search(className) > -1){
                this.setState({ inPopup: true });
                break;
            }
        }
        while(a.nodeName !== 'BODY' && a.className.toLowerCase().search(className) === -1)
    };

    detectHeight = (el) => {
        const main = el.getBoundingClientRect();
        let prevValue = JSON.stringify(main);
        const handle = setInterval(() => {
            let nextValue = JSON.stringify(main);
            if (nextValue === prevValue) {
                clearInterval(handle);
                let height = main.height;
                this.setState({ customHeight: height });
            } else {
                prevValue = nextValue;
            }
        }, 50);
    };

    render() {
        let { allwaysTop } = this.props;
        const { upperCase, theme, width, height, title, style, className, colorBorder, showBorder, onBack, children, alignment } = this.props;
        const { customHeight, inPopup } = this.state;
        const classes = useStyles();

        // if allwaysTop is undefined => default true
        // if allwaysTop is undefined && Action Toolbar is inside Popup|Modal|PopupOver|Tooltip => false
        if(typeof allwaysTop === "undefined") allwaysTop = !inPopup;

        let border = "none";
        if (showBorder || typeof showBorder === "undefined") {
            border = "0.5px solid " + (colorBorder ? colorBorder : (theme ? theme.palette.divider : "#E0E0E0"));
        }

        // custom style normal and allwaysTop
        let customStyle = {
            borderBottom: border,
            width: Config.isMobile ? '100%' : 'calc(100% - 80px)',
            maxWidth: Config.isMobile ? '100%' : 'calc(100% - 30px)',
            marginLeft: -15,
        };

        if(!allwaysTop){
            customStyle = {
                marginTop: -15,
                borderBottom: border
            }
        }

        return (
            <React.Fragment>
                <div className={`${allwaysTop ? classes.onTopDiv : classes.fullWidth}`}
                     style={{ height: allwaysTop && customHeight && !height ? customHeight : 'auto'}}>

                    <div className={`display_row align-center flex-wrap action-toolbar ${className} ${allwaysTop ? classes.onTop : ''}`}
                         id={"action-toolbar"}
                         ref={el=>{
                             if (!el) return;

                             // if allwaysTop is undefined then check is inside modal|tooltip|popup|popover
                             if(typeof this.props.allwaysTop === 'undefined') this.isPopup(el);

                             this.detectHeight(el);
                         }}
                         style={{
                             padding: customHeight > 80 ? '10px 15px' : '0 15px',
                             ...customStyle,
                             ...style,
                         }}>

                        {(title || onBack) &&
                            <Typography
                                type={"h1"}
                                style={{ minHeight: 50, display:'flex' }}
                                className={"action-toolbar-title " + (typeof upperCase === "undefined" || upperCase ? classes.title : "")}
                            >
                                {onBack &&
                                    <ButtonIcon
                                        circular
                                        viewBox
                                        viewType={"text"}
                                        name={"Back"}
                                        onClick={onBack}
                                    />
                                }
                                {title && title}
                            </Typography>
                        }

                        {children &&
                            <div className={classes.root}
                                 style={{
                                     width: width, ...(!height ? { minHeight: 50 } : {height: height}),
                                     justifyContent: alignment ? alignment : 'flex-start'
                                 }}>
                                {children}
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ActionToolbar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.any,
    title: PropTypes.string,
    height: PropTypes.any,
    width: PropTypes.any,
    colorBorder: PropTypes.string,
    showBorder: PropTypes.bool,
    upperCase: PropTypes.bool,
    alignment: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-around', 'space-between']),
    onBack: PropTypes.func,
    allwaysTop: PropTypes.bool
};

export default ActionToolbar;
