/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/21/2019
 * @Example
 */

import React, { Component } from 'react';

import validator from 'validator';
import Config from "../../../config";
import LocalizedStrings from "react-localization";

var validatorExt = {


    isName(value) {
        if (value.length === 0)
            return false;
        var regex = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/g;
        return !regex.test(value);
    },

    isPhone(value) {
        if (value.length === 0) return false;
        var regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        return regex.test(value);
    },

    isPassword(value) {
        // at least one Lower, Upper
        //at least 8 num - max 20
        //no spacing
        // var regex = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/im;
        //var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?,!@$%^&*_.()=+|:;-])(?=\S+$).{8,20}$/;
        var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=\S+$).{8,20}$/;
        return regex.test(value);
    },

    isPasswordLogin(value) {
        //var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/; old
        var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=\S+$).{8,20}$/;
        return regex.test(value);
    },

    isRequired(value) {
        return (value.length !== 0);
    },

    isEmail(value) {
        var args = [];
        args.push(value);
        var _validatorEmail = validator["isEmail"];
        var _isValidEmail = _validatorEmail.apply(null, args);

        return _isValidEmail;
    },

    isEqual(value, comparison) {
        return (value === comparison);
    },

};

class MForm extends Component {

    static erroHelperText = {
        "isRequired": Config.lang("Truong_nay_bat_buoc_nhap"),
        "isName": Config.lang("Truong_nay_khong_hop_le"),
        "isPhone": Config.lang("So_dien_thoai_khong_hop_le"),
        "isPassword": Config.lang("Truong_nay_khong_hop_le"),
        "isPasswordLogin": Config.lang("Truong_nay_khong_hop_le"),
        "isEmail": Config.lang("Email_khong_hop_le"),
        "isEqual": Config.lang("Cac_truong_khong_bang_nhau"),
    };

    constructor(props) {
        super(props);
        let { data } = this.props;
        let state = {};
        data.forEach((i) => {
            state[i.id] = {
                isNull: (i.validator === 'isNull'),
                validate: null,
                value: (i.def_value !== '') ? i.def_value : "",
                def_value: (i.def_value !== '') ? i.def_value : "",
                type: i.type
            };
        });
        this.state = state;
        // console.log(state);
    }

    static formValidation = (validate) => {
        let cfLocalize = null;
        let lang = Config.getLocalStorage("langBI");
        if (!lang || lang.length !== 2){
            lang = "vi";
            Config.setLocalStorage('langBI','vi');
        }
        if (Config.localization) {
            try {
                const lc = Config.getLocalStorage('LOCALIZE');
                cfLocalize = lc ? JSON.parse(lc) : null;

                if (window.localize) {
                    cfLocalize = window.localize;
                    Config.setLocalStorage('LOCALIZE',JSON.stringify(cfLocalize));
                }

            } catch (e) {

            }
            Config.localization = new LocalizedStrings(cfLocalize);
            Config.localization.setLanguage(lang);
        }
        const errorText = this.erroHelperText;
        let results = {};
        if (validate) {
            validate.forEach((v) => {
                /**
                 * @param name: string
                 * @param rules: array
                 * @param value: string
                 */
                if (v.name) {
                    const rules = !Array.isArray(v.rules) ? [v.rules] : v.rules;
                    for (let r of rules) {
                        let isValid = true;
                        switch (r) {
                            case "isRequired":
                                isValid = validatorExt.isRequired(v.value);
                                if (!isValid) {
                                    results = {
                                        ...results,
                                        [v.name]: errorText.isRequired
                                    };
                                }
                                break;
                            case "isName":
                                isValid = validatorExt.isName(v.value);
                                if (!isValid) {
                                    results = {...results, [v.name]: errorText.isName};
                                }
                                break;
                            case "isPhone":
                                isValid = validatorExt.isPhone(v.value);
                                if (!isValid) {
                                    results = {...results, [v.name]: errorText.isPhone};
                                }
                                break;
                            case "isPassword":
                                isValid = validatorExt.isPassword(v.value);
                                if (!isValid) {
                                    results = {...results, [v.name]: errorText.isPassword};
                                }
                                break;
                            case "isPasswordLogin":
                                isValid = validatorExt.isPasswordLogin(v.value);
                                if (!isValid) {
                                    results = {...results, [v.name]: errorText.isPasswordLogin};
                                }
                                break;
                            case "isEmail":
                                isValid = validatorExt.isEmail(v.value);
                                if (!isValid) {
                                    results = {...results, [v.name]: errorText.isEmail};
                                }
                                break;
                            case "isEqual":
                                isValid = validatorExt.isEqual(v.value1, v.value2);
                                if (!isValid) {
                                    results = {...results, [v.name]: errorText.isEqual};
                                }
                                break;
                            default:
                                break;
                        }
                        if (!isValid) break;
                    };
                }
            });
        }
        return results;
    };

    static isRequired = (value, name) => {
        const errorText = this.erroHelperText;
        if (name) {
            const isValid = validatorExt.isRequired(value);
            if (!isValid)
            return {[name]: !isValid ? errorText.isRequired : ""};
        }
        return false;
    };

    static isName = (value, name) => {
        const errorText = this.erroHelperText;
        if (name) {
            const isValid = validatorExt.isName(value);
            if (!isValid)
                return {[name]: !isValid ? errorText.isName : ""};
        }
        return false;
    };

    static isPhone = (value, name) => {
        const errorText = this.erroHelperText;
        if (name) {
            const isValid = validatorExt.isPhone(value);
            if (!isValid)
                return {[name]: !isValid ? errorText.isPhone : ""};
        }
        return false;
    };

    static isPassword = (value, name) => {
        const errorText = this.erroHelperText;
        if (name) {
            const isValid = validatorExt.isPassword(value);
            if (!isValid)
                return {[name]: !isValid ? errorText.isPassword : ""};
        }
        return false;
    };

    static isPasswordLogin = (value, name) => {
        const errorText = this.erroHelperText;
        if (name) {
            const isValid = validatorExt.isPasswordLogin(value);
            if (!isValid)
                return {[name]: !isValid ? errorText.isPasswordLogin : ""};
        }
        return false;
    };

    static isEmail = (value, name) => {
        const errorText = this.erroHelperText;
        if (name) {
            const isValid = validatorExt.isEmail(value);
            if (!isValid)
                return {[name]: !isValid ? errorText.isEmail : ""};
        }
        return false;
    };

    static isEqual = (value, name) => {
        const errorText = this.erroHelperText;
        if (name) {
            const isValid = validatorExt.isEqual(value);
            if (!isValid)
                return {[name]: !isValid ? errorText.isEqual : ""};
        }
        return false;
    };

    render() {
        let { data } = this.props;
        return (
            <form className={this.props.className || ""}>
                {data.map((key, idx) => {
                    return (
                        <React.Fragment>
                        {/*<FormGroup key={idx} validationState={this.state[key.id].validate} className={key.styleRow}>*/}

                        {/*    {key.label && (*/}
                        {/*        <div className={key.styleLabel}>*/}
                        {/*            {key.label}*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*    {key.type === 'TEXT' && (*/}
                        {/*        <TextBoxForm*/}
                        {/*            data={key}*/}
                        {/*            onChangeText={(text) => this._onChangeText(text, key)} />*/}
                        {/*    )}*/}

                        {/*    {key.type === 'TEXT_AREA' && (*/}
                        {/*        <TextAreaForm*/}
                        {/*            data={key}*/}
                        {/*            onChangeText={(text) => this._onChangeText(text, key)} />*/}
                        {/*    )}*/}

                        {/*    {key.type === 'DROP_DOWN' && (*/}
                        {/*        <DropDownForm*/}
                        {/*            data={key}*/}
                        {/*            onValueChange={(id) => this._onValueChange(id, key)} />*/}
                        {/*    )}*/}

                        {/*    {key.type === 'DATE' && (*/}
                        {/*        <DateForm*/}
                        {/*            data={key}*/}
                        {/*            onDateChange={(dt) => this._onDateChange(dt, key)} />*/}
                        {/*    )}*/}

                        {/*    /!*{key.type === 'PHONE' && (*!/*/}
                        {/*    /!*    <PhoneForm*!/*/}
                        {/*    /!*        data={key}*!/*/}
                        {/*    /!*        onPhoneChange={(dt) => this._onPhoneChange(dt, key)} />*!/*/}
                        {/*    /!*)}*!/*/}

                        {/*    {key.feedback && (*/}
                        {/*        <FormControl.Feedback />*/}
                        {/*    )}*/}
                        {/*</FormGroup>*/}
                        </React.Fragment>
                    )
                })}
            </form>
        )
    }
}

export default MForm;
