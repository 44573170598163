/**
 * @copyright 2019 @ DigiNet
 * @author TRANGHOANG
 * @create 1/21/2020
 */
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from '@material-ui/icons/Close';
import { Button, Checkbox, DatePicker, Radio } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Col, Row } from 'react-bootstrap';
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { Input } from "reactstrap";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W94F1000Actions from "../../../../redux/W9X/W94F1000/W94F1000_actions";
import { Loading } from "../../../common/loading/loading";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Combo from "../../../libs/combo/combo";

const { RangePicker } = DatePicker;
const styles = theme => {
    return {
        btnPrint: {
            [theme.breakpoints.down(768)]: {
                '& span>.mgl5': {
                    display: 'none'
                }
            },
        },
        formInfo: {
            padding: 15,
            '& .mgt-15': {
                marginTop: 15
            }
        }
    }
};

class W94F1002 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateLoading: false,
            showPreview: false,
            loadingStatus: false,
            exportLoading: false,
            heightPreview: 0,
            detailChart: null,
            blobUrl: "",
            downloadURL: "",
            downloadName: "",
            downloadURLExport: "",
            downloadNameExport: "",
            loading: {},
            dataForm: [],
            requiredFieldNames: [],
            dataFilter: [
                { "id": "UserID", "type": "VarChar", "value": Config.profile.UserID },
                { "id": "DivisionID", "type": "VarChar", "value": Config.profile.DivisionID }
            ]
        };
        this.formMaster = null;
        this.minHeightView = 550;
        this.loading = {};
    }

    getInfo = (flag) => {
        const { location } = this.props;
        const { detailChart } = this.state;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get("MReportID")) {
            return {
                MReportID: url.get("MReportID"),
                URL: detailChart.URL || "",
                strSQL: detailChart.strSQL || "",
                Sheet: detailChart.Sheet || "",
                FileName: detailChart.FileName || ""
            };
        } else if (location && location.state) {
            return {
                MReportID: location.state.MReportID,
                URL: location.state.URL,
                strSQL: location.state.strSQL,
                Sheet: location.state.Sheet,
                FileName: location.state.FileName,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W94F1000");
                return null;
            }
            return false;
        }
    };

    componentWillUnmount() {
        if (this.tempWindow) this.tempWindow.close();
    }

    componentDidMount = () => {
        Config.filters.renderHTML(() => { return null; });
        if (!this.getInfo(true)) return null;
        this.getDetailAndControls();
    };

    getDetailAndControls = () => {
        const { MReportID } = this.getInfo();
        const { listGroupChart } = this.props;
        const { dataForm } = this.state;
        this.setState({ loadingStatus: true });
        this.props.W94F1000Actions.getFormMaster({ MReportID: MReportID }, (err, data) => {
            if (err) {
                this.setState({ loadingStatus: false });
                let message = err.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const requiredFieldNames = (data.filter(item => item.IsRequired === 1)).map(obj => (
                    {
                        id: obj.ControlID,
                        name: Config.language === "01" ? obj.Caption01 : obj.CaptionName84
                    }
                ));
                const dataTemp = data.sort(function (a, b) {
                    // Compare the 2 dates
                    if (a.DisplayOrder < b.DisplayOrder) return -1;
                    if (a.DisplayOrder > b.DisplayOrder) return 1;
                    return 0;
                });
                this.props.W94F1000Actions.getStructReport({ MReportID: MReportID }, (errStruct, dataStruct) => {
                    this.setState({ loadingStatus: false });
                    if (errStruct) {
                        let message = errStruct.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    }
                    if (dataStruct && dataForm) {
                        //gán dataCombo, valueExpr, displayExpr cho combo
                        dataStruct.forEach(struct => {
                            dataTemp.forEach((control, controlIdx) => {
                                if (control.ControlID === struct.ControlID && (control.ControlType === "C" || control.ControlType === "CC" || control.ControlType === "OPT")) {
                                    if (!dataTemp[controlIdx].dataCombo) dataTemp[controlIdx].dataCombo = struct.rows;
                                    if (!dataTemp[controlIdx].fields) dataTemp[controlIdx].fields = struct.fields;
                                    struct.fields.forEach(type => {
                                        if (type.Style.includes("V")) dataTemp[controlIdx].valueExpr = type.FieldName;//displayExpr
                                        if (type.Style.includes("D")) dataTemp[controlIdx].displayExpr = type.FieldName;//displayExpr
                                    });
                                }
                            });
                        });
                        dataTemp.forEach(temp => {
                            // check dataFilter with default
                            if (temp.ControlType === "C" || temp.ControlType === "CC") {
                                const percent = temp.ControlType === "CC" ? ["%"] : "%";
                                if (temp.DefaultValue === "%") {
                                    temp.defaultValue = percent;
                                    this.handleData(temp.ControlID, "VarChar", percent);
                                }
                                else {
                                    let item = (!_.isEmpty(temp.dataCombo) ? temp.dataCombo.find(i => i[temp.valueExpr] === temp.DefaultValue) : null) || temp.DefaultValue;
                                    let valueCombo = ((temp.DefaultValue || temp.DefaultValue === 0) && item) ? ((_.isString(item[temp.valueExpr]) && temp.ControlType === "CC") ? [item[temp.valueExpr]] : item[temp.valueExpr]) : null;
                                    if (!_.isEmpty(dataStruct)) {
                                        let dataComboCC = dataStruct.find(item => item.ControlID === temp.ControlID);
                                        dataComboCC = dataComboCC?.rows || [];
                                        if (!_.isEmpty(dataComboCC)) {
                                            const controlDepend = temp.ControlDepend ? temp.ControlDepend.split(',') : [];
                                            if (controlDepend.length > 0) {
                                                const controls = dataTemp.filter(k => controlDepend.includes(k.ControlID));
                                                for (let control of controls) {
                                                    dataComboCC = dataComboCC.filter(d => d[control.valueExpr] === control.defaultValue);
                                                }
                                            }
                                            if (dataComboCC[0].hasOwnProperty(temp.valueExpr)) {
                                                valueCombo = (dataComboCC[0])[temp.valueExpr]; // Combo ("C","CC") default giá trị dòng đầu tiên
                                            }
                                        }
                                        if (temp.ControlType === "CC") {
                                            if (!Array.isArray(valueCombo)) valueCombo = [valueCombo];
                                            if (_.isEmpty(valueCombo) && _.isString(item)) item = item.split(",");
                                        }
                                    }
                                    temp.defaultValue = valueCombo || item;
                                    this.handleData(temp.ControlID, "VarChar", valueCombo || item);
                                }
                            }
                            if (temp.ControlType === "T") this.handleData(temp.ControlID, "VarChar", temp.DefaultValue);
                            if (temp.ControlType === "D") this.handleData(temp.ControlID, "VarChar", moment().format("YYYY-MM-DD"));
                            if (temp.ControlType === "CHK") this.handleData(temp.ControlID, "INT", parseInt(temp.DefaultValue) === 0 || parseInt(temp.DefaultValue) === 1 ? parseInt(temp.DefaultValue) : null);
                            if (temp.ControlType === "OPT") this.handleData(temp.ControlID, "VarChar", temp.DefaultValue ? temp.DefaultValue : null);
                        });
                        this.setState({
                            dataForm: dataTemp,
                            requiredFieldNames
                        });
                    }
                });
            }
        });
        if (!listGroupChart) {
            this.props.W94F1000Actions.getDetail({ MReportID: MReportID }, (err, data) => {
                if (data && data.length > 0) {
                    this.setState({
                        detailChart: data[0]
                    }, () => {
                        Config.filters.renderHTML(() => this.renderHeader(data[0]));
                    });
                }
                else {
                    Config.popup.show("INFO", err.message);
                }
            });
        }
        else if (listGroupChart && listGroupChart.Detail) {
            const item = listGroupChart.Detail.find(i => i.MReportID === MReportID);
            if (item) {
                Config.filters.renderHTML(() => this.renderHeader(item));
                this.setState({
                    detailChart: item
                })
            }
            else {
                Config.popup.show("INFO", Config.lang("BI_Khong_co_du_lieu"))
            }
        }
    }

    getHeightOfViewer = () => {
        let height = 0;
        if (this.formMaster) {
            const headerHeight = 50;
            const hMaster = this.formMaster.offsetHeight;
            const hw = window.innerHeight;
            height = hw - (hMaster + headerHeight + 57);
        }
        return height && height < this.minHeightView ? this.minHeightView : height;
    };

    renderHeader = (detailChart) => {
        return (
            <ActionToolbar
                title={detailChart && detailChart.MReportName}
                classTitle={"txt-two-line"}
                styleTitle={{
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    overflow: 'inherit'
                }}
                alignment={"flex-end"}
                showBorder={false}
                style={{
                    marginTop: 0,
                    flexWrap: 'nowrap'
                }}
                allwaysTop={false}
            >
            </ActionToolbar>
        )
    };

    handleData = (key, type, vl) => {
        const dataFilterT = type === "DATETIME" ? this.state.dataFilter : [...this.state.dataFilter];
        if (!Array.isArray(key)) {
            const item = {
                id:    key,
                type:  type,
                value: vl
            };
            let isExit = false;
            dataFilterT.forEach((i, idx) => {
                if (i.id === key) {
                    dataFilterT[idx] = item;
                    isExit           = true;
                }
            });
            if (!isExit) {
                dataFilterT.push(item);
            }
        } else {
            for (let v of key) {
                if (Array.isArray(v) && v.length === 3) {
                    const item = {
                        id:    v[0],
                        type:  v[1],
                        value: v[2]
                    };
                    let isExit = false;
                    dataFilterT.forEach((i, idx) => {
                        if (i.id === v[0]) {
                            dataFilterT[idx] = item;
                            isExit           = true;
                        }
                    });
                    if (!isExit) {
                        dataFilterT.push(item);
                    }
                }
            }
        }
        this.setState({
            dataFilter: dataFilterT
        });
    };

    _setLoading = (key, value, cb) => {
        if (!key) return false;
        if (typeof key === "object") {
            this.loading = {
                ...this.loading,
                ...key
            };
        } else {
            this.loading[key] = value;
        }
        this.setState({
            loading: {
                ...this.loading
            }
        }, () => {
            if (cb) cb();
        });
    };

    checkRequired = (requiredFieldNames, dataFilter) => {
        let requiredFieldName = [];
        if (!_.isEmpty(requiredFieldNames)) {
            dataFilter.forEach(obj => {
                if ( (!obj.value && obj.value !== 0) || (Array.isArray(obj.value) && _.isEmpty(obj.value)) ) {
                    const objName = requiredFieldNames.find(item => item.id === obj.id);
                    if (objName && objName.name) {
                        if (!obj.hasOwnProperty("disable")) { // Không có field  disable thì kiểm tra required bình thường
                            requiredFieldName.push(objName.name);
                        } else {// có field
                            if ((!obj.disable)) { // Và disable = false thì mới có thông báo
                                requiredFieldName.push(objName.name);
                            }
                        }
                    }
                }
            })
        }
        return { requiredFieldName };
    }

    changeValueNullToString = (array) => {
        if (!_.isArray(array) || _.isEmpty(array)) return null;
        const storeParams = array.map(obj => {
            const item = { ...obj };
            if (item && (_.isNull(item.value) || _.isUndefined(item.value))) {
                item.value = "";
            }
            else {
                if (_.isArray(item.value)) {
                    if (!_.isEmpty(item.value)) {
                        const customValue = item.value.map(valueStr => {
                            return typeof valueStr === "number" ? `${valueStr}` : `''${valueStr}''`;
                        })
                        item.value = `(${customValue})`;
                    } else {
                        item.value = "";
                    }
                }
            }
            return { ...item }
        })
        return storeParams;
    }

    exportExcelTemplate = (isPreview) => {
        const { dataFilter, requiredFieldNames } = this.state;
        const { requiredFieldName } = this.checkRequired(requiredFieldNames, dataFilter);
        if (!_.isEmpty(requiredFieldName)) {
            const message = `${requiredFieldName.length > 1 ? Config.lang("Ban_phai_nhap_cac_thong_tin") : Config.lang("Ban_phai_nhap_thong_tin")}: ${requiredFieldName.join(", ")}`;
            Config.popup.show("INFO", message);
            return null;
        }
        const { URL, strSQL, Sheet = 1, FileName = "" } = this.getInfo();
        const isolateDataFilter = dataFilter.map(dataObj => { //remove disableFiled
            if (dataObj.hasOwnProperty("disable")) {
                dataObj = _.omit(dataObj, "disable");
            }
            return dataObj;
        });
        const params = {
            token: Config.token.id,
            URL,
            strSQL,
            isPDF: 0,
            Sheet,
            FileName,
            StoreParams: JSON.stringify(this.changeValueNullToString(isolateDataFilter))
        };
        this._setLoading(isPreview ? "Preview" : "Export", true);
        this.props.generalActions.exportExcelTemplate(params, (errors, data) => {
            this._setLoading(isPreview ? "Preview" : "Export", false);
            if (errors) {
                let message = Config.lang("BI_Loi_chua_xac_dinh");
                switch (errors.code) {
                    case "EX008":
                        message = Config.lang("BI_Mau_bao_cao_khong_duoc_tim_thay");
                        break;
                    case "MSQ005":
                        message = Config.lang("BI_Du_lieu_xuat_excel_rong");
                        break;
                    case "MSQ006":
                        message = Config.lang("BI_Truong_ngay_la_bat_buoc");
                        break;
                    case "EXP001":
                        message = Config.lang("BI_Du_lieu_dau_vao_la_bat_buoc");
                        break;
                    default:
                        break;
                }
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({
                    downloadURLExport: data.URL || "",
                    downloadNameExport: data.fileName || "",
                }, () => {
                    if (!isPreview) {
                        let el = document.getElementById("linkExportExcel_1");
                        el.click();
                    } else {
                        this.setState({ showPreview: true });
                    }
                });
            }
        });
    };

    beforePreviewExcelTemplate = () => {
        const { dataFilter, requiredFieldNames } = this.state;
        const { requiredFieldName } = this.checkRequired(requiredFieldNames, dataFilter);
        if (!_.isEmpty(requiredFieldName)) {
            const message = `${requiredFieldName.length > 1 ? Config.lang("Ban_phai_nhap_cac_thong_tin") : Config.lang("Ban_phai_nhap_thong_tin")}: ${requiredFieldName.join(", ")}`;
            Config.popup.show("INFO", message);
            return null;
        }
        const { URL = "", strSQL: SQL = "", Sheet = 1, FileName = "" } = this.getInfo();
        const isolateDataFilter = dataFilter.map(dataObj => { //remove disableFiled
            if (dataObj.hasOwnProperty("disable")) {
                dataObj = _.omit(dataObj, "disable");
            }
            return dataObj;
        });
        const params = {
            URL,
            SQL,
            IsPDF: 0,
            Sheet,
            FileName,
            Params: JSON.stringify(this.changeValueNullToString(isolateDataFilter))
        };
        this._setLoading("Preview", true);
        this.props.generalActions.passParametersExportExcel(params, (errors, data) => {
            this._setLoading("Preview", false);
            if (errors) {
                let message = Config.lang("BI_Loi_chua_xac_dinh");
                switch (errors.code) {
                    case "EX008":
                        message = Config.lang("BI_Mau_bao_cao_khong_duoc_tim_thay");
                        break;
                    case "MSQ005":
                        message = Config.lang("BI_Du_lieu_xuat_excel_rong");
                        break;
                    case "MSQ006":
                        message = Config.lang("BI_Truong_ngay_la_bat_buoc");
                        break;
                    case "EXP001":
                        message = Config.lang("BI_Du_lieu_dau_vao_la_bat_buoc");
                        break;
                    default:
                        break;
                }
                Config.popup.show("INFO", message);
                return false;
            }
            if (data && data.id) {
                this.previewExcelTemplate(data.id);
            } else {
                Config.popup.show("INFO", Config.lang("BI_Loi_chua_xac_dinh"));
                return false;
            }
        });
    };

    previewExcelTemplate = async (ID) => {
        if (!ID) return;

        let url = Config.env.api + "/export/preview-excel-report?";

        const params = {
            token: Config.token.id,
            ID: ID
        };

        let param = Object.keys(params).map(function (k) {
            return k + "=" + params[k];
        }).join("&");

        url = url + encodeURIComponent(param);
        this.setState({
            showPreview: false,
            heightPreview: this.getHeightOfViewer(),
        }, () => {
            this.setState({
                downloadURL: url,
                showPreview: true
            }, () => {
                this._setLoading("Preview", true);
            });
        });

    };

    loadedOnPreview = () => {
        this._setLoading("Preview", false);
    };

    onClosePreview = () => {
        this.loading = {};
        this.setState({ showPreview: false, loading: this.loading });
    };

    checkDatePickerType = (format) => {
        let pickerType = "";
        let formatTimes = "YYYY-MM-DD";
        switch (true) {
            case format.length === 7: // mm/yyyy
                pickerType = "month";
                formatTimes = "YYYY-MM"
                break;
            case format.length === 4: // yyyy
                pickerType = "year";
                formatTimes = "YYYY";
                break;
            default:
                break;
        }
        return { pickerType, formatTimes };
    }

    checkControlStatus = (formInfo) => {
        let controlStatus = false;
        let checkBoxChanged = false;
        let defaultOriginalValue = false;
        let currentDCEvalue = formInfo.DependControlEnabled;
        let currentDCEFieldName = formInfo.DependControlEnabled;
        const { dataForm, dataFilter } = this.state;
        if (!_.isEmpty(formInfo.DependControlEnabled)) {
            const valueIndex = currentDCEvalue.split(",")[1];
            const fieldNameIndex = currentDCEFieldName.split(",")[0];
            let bracketNameIndex = fieldNameIndex.indexOf("(");
            let bracketValueIndex = valueIndex.indexOf(")");
            if (bracketNameIndex === 0) bracketNameIndex = bracketNameIndex + 1;
            if (bracketNameIndex !== -1) currentDCEFieldName = fieldNameIndex.slice(bracketNameIndex, fieldNameIndex.length);
            if (bracketValueIndex !== -1) currentDCEvalue = _.toNumber(valueIndex.slice(0, bracketValueIndex));
            const ownerDepentControl = dataForm.find(item => item.ControlID === currentDCEFieldName);
            const ownerDepentControlChanged = dataFilter.find(item => item.id === currentDCEFieldName); //CheckBox 1 and 0 only
            const ownerDepentControlDevaultValue = _.toNumber(ownerDepentControl.DefaultValue);
            checkBoxChanged = ownerDepentControlChanged && ownerDepentControlChanged.value !== currentDCEvalue; // Nếu Control hiện tại là 1 thì ẩn Control có cùng ControlID
            defaultOriginalValue = ownerDepentControlDevaultValue !== currentDCEvalue;
            controlStatus = ownerDepentControlChanged && ownerDepentControlChanged.value === 1 ? checkBoxChanged : defaultOriginalValue;
        }
        return { controlStatus };
    }

    checkRequiredStatusData = (disable, formInfo) => {
        const { dataFilter } = this.state;
        if (disable) {
            dataFilter.forEach(item => {
                if (item.id === formInfo.ControlID) {
                    if (_.isEmpty(item.value)) {
                        item.disable = true;
                    }
                }
            });
        } else {
            dataFilter.forEach(item => {
                if (item.id === formInfo.ControlID) {
                    if (_.isEmpty(item.value) && item.disable) {
                        item.disable = false;
                    }
                }
            });
        }
    }

    renderInput = (formInfo, idxForm) => {
        const { dataFilter } = this.state;
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(';').forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(',');
                dataFilter && dataFilter.forEach(i => {
                    const value = i.value ? i.value.toString() : i.value;
                    const tmp = temp[1] ? temp[1].toString() : temp[1];
                    if (i.id === temp[0] && value === tmp) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(';').length);
        }
        const value = dataFilter.find(i => i.id === formInfo.ControlID);
        this.checkRequiredStatusData(disable, formInfo); // Dùng để chek Required Theo Disable
        return (
            <Col key={idxForm}
                className={"mgt-15"}
                lg={this.getColSpan(formInfo.ColSpan, 6)}
                md={this.getColSpan(formInfo.ColSpan, 6)}
                sm={this.getColSpan(formInfo.ColSpan, 6)}
                xs={12}
            >
                <label>{formInfo.CaptionName84}</label>
                <Input disabled={disable}
                    style={{ width: '100%', height: 32, borderRadius: 2, boxShadow: 'none' }}
                    defaultValue={value ? value.value : ""}
                    placeholder={formInfo.CaptionName84}
                    onChange={vl => this.handleData(formInfo.ControlID, "VarChar", vl.target.value)} />
            </Col>
        );
    };

    renderDatePicker = (formInfo, idxForm) => {
        const { dataForm, dataFilter } = this.state;
        const from = "From";
        const to = "To";
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(";").forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(",");
                dataFilter.forEach(i => {
                    const value = i.value ? i.value.toString() : i.value;
                    const tmp = temp[1] ? temp[1].toString() : temp[1];
                    if (i.id === temp[0] && value === tmp) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(";").length);
        }
        let controlDateName = formInfo.ControlID;
        if (formInfo.ControlID.search(from) > -1) controlDateName = formInfo.ControlID.slice(0, formInfo.ControlID.length - from.length);
        if (formInfo.ControlID.search(to) > -1) controlDateName = formInfo.ControlID.slice(0, formInfo.ControlID.length - to.length);
        const controlDateFrom = controlDateName + "From";
        const controlDateTo = controlDateName + "To";
        const formatType = (formInfo.Format && formInfo.Format.toUpperCase()) || "";
        const { pickerType, formatTimes } = this.checkDatePickerType(formatType);
        this.checkRequiredStatusData(disable, formInfo); // Dùng để chek Required Theo Disable
        // <=====================>Điều kiện Date cũ<=====================>
        // if (dataForm.find(i => i.ControlID === "DateTo") && dataForm.find(i => i.ControlID === "DateFrom") && formInfo.ControlID === "DateFrom"){
        // } else if (((formInfo.ControlID !== "DateTo" && formInfo.ControlID !== "DateFrom") || ((formInfo.ControlID === "DateTo" || formInfo.ControlID === "DateFrom") && (!dataForm.find(i => i.ControlID === "DateTo") || !dataForm.find(i => i.ControlID === "DateFrom"))))) {
        if (dataForm.find(item => item.ControlID === controlDateFrom) && dataForm.find(item => item.ControlID === controlDateTo)) {
            if (formInfo.ControlID === controlDateFrom) {
                let fieldName = Config.lang("Thoi_gian");
                const languageModeName = Config.language === "01" ? "Caption01" : "CaptionName84";
                const findFromToName = dataForm.filter(o => (o.ControlID === controlDateTo || o.ControlID === controlDateFrom));
                if (findFromToName.length === 2) {
                    fieldName = `${(findFromToName[0])[languageModeName]} - ${(findFromToName[1])[languageModeName]}`;
                }
                return (
                    <Col key={idxForm}
                        className={"mgt-15"}
                        lg={this.getColSpan(formInfo.ColSpan, 6)}
                        md={this.getColSpan(formInfo.ColSpan, 6)}
                        sm={this.getColSpan(formInfo.ColSpan, 6)}
                        xs={12}
                    >
                        <label>{`${fieldName}`}</label>
                        <RangePicker style={{ width: '100%' }}
                            format={formatType || "DD/MM/YYYY"}
                            locale={locale}
                            disabled={disable}
                            {...(!_.isEmpty(pickerType) ? {
                                picker: { pickerType }
                            } : {})}
                            onCalendarChange={(date) => {
                                this.handleData(controlDateFrom, "DATETIME", date && date[0] ? moment(date[0]).format(formatTimes) : null);
                                this.handleData(controlDateTo, "DATETIME", date && date[1] ? moment(date[1]).format(formatTimes) : null);

                            }}
                            defaultValue={[formInfo.DefaultValue === "@NowDate" ? moment() : null, formInfo.DefaultValue === "@NowDate" ? moment() : null]}
                        />
                    </Col>
                )
            }
        } else {
            if (!_.isEmpty(formInfo)) {
                return (
                    <Col key={idxForm}
                        className={"mgt-15"}
                        lg={this.getColSpan(formInfo.ColSpan, 6)}
                        md={this.getColSpan(formInfo.ColSpan, 6)}
                        sm={this.getColSpan(formInfo.ColSpan, 6)}
                        xs={12}
                    >
                        <label>{formInfo.CaptionName84}</label>
                        <DatePicker style={{ width: '100%' }}
                            placeholder={formInfo.CaptionName84}
                            format={formatType || "DD/MM/YYYY"}
                            {...(!_.isEmpty(pickerType) ? {
                                picker: { pickerType }
                            } : {})}
                            locale={locale}
                            disabled={disable}
                            onChange={(date) => {
                                this.handleData(formInfo.ControlID, "DATETIME", date ? moment(date).format(formatTimes) : null);
                            }}
                            defaultValue={formInfo.DefaultValue === "@NowDate" ? moment() : null}
                        />
                    </Col>
                )
            } else {
                return null
            }
        }
    };

    renderCheckBox = (formInfo, idxForm) => {
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            //<=====================>Điều kiện disable cũ<=====================>
            // disable = [];
            // formInfo.DependControlEnabled.split(';').forEach((depend) => {
            //     let temp = depend;
            //     temp = temp.slice(1, temp.length - 1);
            //     temp = temp.split(',');
            //     dataFilter.forEach(i => {
            //         const value = i.value ? i.value.toString() : i.value;
            //         const tmp = temp[1] ? temp[1].toString() : temp[1];
            //         if (i.id === temp[0] && value === tmp) {
            //             disable.push(false)
            //         }
            //     });
            // });
            // disable = !(disable.length === formInfo.DependControlEnabled.split(';').length);
            const { controlStatus } = this.checkControlStatus(formInfo);
            disable = controlStatus;
        }
        this.checkRequiredStatusData(disable, formInfo); // Dùng để chek Required Theo Disable
        return (
            <Col key={idxForm}
                className={"mgt-15"}
                lg={this.getColSpan(formInfo.ColSpan, 6)}
                md={this.getColSpan(formInfo.ColSpan, 6)}
                sm={this.getColSpan(formInfo.ColSpan, 6)}
                xs={12}
                style={{ minHeight: 57, display: 'flex', alignItems: 'center' }}
            >
                <Checkbox defaultChecked={parseInt(formInfo.DefaultValue) === 1}
                    disabled={disable}
                    onChange={(e) => {
                        this.handleData(formInfo.ControlID, "INT", e.target.checked ? 1 : 0);
                    }}>
                    {formInfo.CaptionName84}
                </Checkbox>
            </Col>
        );
    };

    renderRadio = (formInfo, idxForm) => {
        const { dataFilter } = this.state;
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(";").forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(",");
                dataFilter.forEach(i => {
                    const value = i.value ? i.value.toString() : i.value;
                    const tmp = temp[1] ? temp[1].toString() : temp[1];
                    if (i.id === temp[0] && value === tmp) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(";").length);
        }
        this.checkRequiredStatusData(disable, formInfo); // Dùng để chek Required Theo Disable
        return (
            <Col key={idxForm}
                className={"mgt-15"}
                lg={this.getColSpan(formInfo.ColSpan, 6)}
                md={this.getColSpan(formInfo.ColSpan, 6)}
                sm={this.getColSpan(formInfo.ColSpan, 6)}
                xs={12}
            >
                <label>{formInfo.CaptionName84}</label>
                <Radio.Group style={{
                    border: '1px solid #d9d9d9', borderRadius: 2, padding: '4px 10px', width: '100%'
                }} disabled={disable} onChange={e => this.handleData(formInfo.ControlID, "VarChar", e.target.value)}
                    name={"radiogroup"}
                    defaultValue={formInfo.DefaultValue ? formInfo.DefaultValue : null}>
                    {formInfo.dataCombo && formInfo.dataCombo.map((combo, idxCombo) => {
                        return (
                            <Radio key={idxCombo}
                                value={combo.Value}>
                                {combo.Lable}
                            </Radio>
                        )
                    })}
                </Radio.Group>
            </Col>
        )
    };

    getColSpan = (vl, def) => {
        return vl ? vl : def;
    };

    clearCombo = (comboChildren) => {
        this.refs[comboChildren.ControlID].setValue(null);
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { dataForm, showPreview, heightPreview, loading, loadingStatus, dataFilter } = this.state;
        const disabled = Object.keys(loading).find(k => loading[k]);
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Row style={{ height: '100%', margin: 0 }} className={classes.formInfo}>
                    <div ref={ref => this.formMaster = ref} style={{ display: 'block', overflow: 'hidden' }}>
                        {dataForm && dataForm.map((formInfo, idxForm) => {
                            if (formInfo.IsHide) return null;
                            switch (formInfo.ControlType) {
                                case "T": return this.renderInput(formInfo, idxForm);
                                case "C":
                                case "CC":
                                    return <SelectCus key={idxForm}
                                        formInfo={formInfo}
                                        idxForm={idxForm}
                                        ref={formInfo.ControlID}
                                        dataFilter={dataFilter}
                                        dataForm={dataForm}
                                        getColSpan={this.getColSpan}
                                        clearCombo={this.clearCombo}
                                        handleData={this.handleData}
                                        checkRequiredStatusData={this.checkRequiredStatusData}
                                    />;
                                case "D": return this.renderDatePicker(formInfo, idxForm);
                                case "CHK": return this.renderCheckBox(formInfo, idxForm);
                                case "OPT": return this.renderRadio(formInfo, idxForm);
                                default: return null;
                            }
                        })}

                        {loadingStatus && <Loading />}
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <Button type={"primary"}
                                shape={"round"}
                                disabled={loadingStatus || Boolean(disabled)}
                                onClick={() => this.exportExcelTemplate()}
                                style={{ marginRight: 10, marginTop: 15 }}
                                icon={<i className={"fa fa-download"} style={{ marginRight: 10 }} />}
                            >
                                {Config.lang("BI_Xuat_bao_cao")}
                            </Button>

                            <Button type={"primary"}
                                shape={"round"}
                                disabled={loadingStatus || Boolean(disabled)}
                                onClick={this.beforePreviewExcelTemplate}
                                style={{ backgroundColor: 'green', borderColor: 'green', marginTop: 15 }}
                                icon={<i className={"fa fa-eye"} style={{ marginRight: 10 }} />}
                            >
                                {Config.lang("BI_Xem_truoc")}
                            </Button>
                        </Col>
                    </div>

                    {showPreview && <Col xs={12} sm={12} md={12} lg={12}>
                        <div className={"display_row align-center valign-bottom"}>
                            <IconButton aria-label={"close"} size={"small"} onClick={this.onClosePreview}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className={"display_row align-center valign-middle"}>
                            {loading.Preview && <Loading className={classes.previewLoading} />}
                            <iframe src={this.state.downloadURL ? "https://view.officeapps.live.com/op/embed.aspx?src=" + this.state.downloadURL : ""}
                                title={this.state.downloadName}
                                onLoad={this.loadedOnPreview}
                                height={heightPreview}
                                // className={classes.view}
                                width={"100%"} />
                        </div>
                    </Col>}
                </Row>

                <a id={"linkExportExcel_1"} className={"hide"} download={this.state.downloadNameExport}
                    href={this.state.downloadURLExport}>File</a>
            </React.Fragment>
        )
    }
}

class SelectCus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null
        }
    }

    setValue = (value, controlType) => {
        controlType === "CC" ? this.setState({
            ...value,
            value
        }) : this.setState({ value })
    };

    getDataSource = () => {
        const {dataFilter, dataForm, formInfo} = this.props;
        let dataSelect = formInfo.dataCombo;
        const controlDepend = formInfo.ControlDepend ? formInfo.ControlDepend.split(',') : [];
        if (controlDepend.length > 0) {
            const controls = dataForm.filter(k => controlDepend.includes(k.ControlID));
            for (let control of controls) {
                const data = dataFilter.find(d => d.id === control.ControlID);
                if (data.value !== "%" && dataSelect) {
                    dataSelect    = _.isArray(data.value)
                        ? dataSelect.filter(obj => {
                            return (obj[control.valueExpr] !== 0 && !obj[control.valueExpr])
                                || obj[control.valueExpr] === "%"
                                || data.value.includes(obj[control.valueExpr]);
                        })
                        : dataSelect.filter(obj => {
                            return (obj[control.valueExpr] !== 0 && !obj[control.valueExpr]) // <> 0 và (bằng NULL hoặc rỗng)
                                || obj[control.valueExpr] === "%" // === "%"
                                || obj[control.valueExpr] === data.value; // Giá trị cụ thể của combo phụ thuộc
                        })
                }
            }
        }
        return dataSelect;
    };

    componentDidMount () {
        const {formInfo, handleData} = this.props;
        const dataSource = this.getDataSource();
        this.setState({
            value: dataSource?.[0]?.[formInfo["valueExpr"]] || ""
        }, () => {
            if (handleData) handleData(formInfo.ControlID, "VarChar", this.state.value);
        });
    }

    render() {
        const { dataFilter, dataForm, formInfo, idxForm, handleData, clearCombo, getColSpan, checkRequiredStatusData } = this.props;
        const { value } = this.state;
        const data = dataFilter.find(d => d.id === formInfo.ControlID);
        //check disabled by DependControlEnabled
        let disable = false;
        if (formInfo.DependControlEnabled) {
            disable = [];
            formInfo.DependControlEnabled.split(";").forEach((depend) => {
                let temp = depend;
                temp = temp.slice(1, temp.length - 1);
                temp = temp.split(",");
                dataFilter.forEach(i => {
                    if (i.id === temp[0] && i.value && temp[1] && i.value.toString() === temp[1].toString()) {
                        disable.push(false)
                    }
                });
            });
            disable = !(disable.length === formInfo.DependControlEnabled.split(";").length);
        }

        //check phụ thuộc combo
        const dataSource = this.getDataSource();
        checkRequiredStatusData(disable, formInfo); // Dùng để chek Required Theo Disable
        const controlChildrens = dataForm.filter(k => {
            const kControlDepend = k.ControlDepend ? k.ControlDepend.split(',') : [];
            return kControlDepend.includes(formInfo.ControlID);
        });
        const valueCombo = formInfo.ControlType === "CC" ? ((value || value === 0) && _.isArray(value) ? value.join(", ") : (value || null)) : value;
        //
        if (!_.isEmpty(valueCombo) && !_.isEmpty(data?.value)
            && JSON.stringify(data?.value) !== JSON.stringify(valueCombo) && handleData) {
            handleData(formInfo.ControlID, "VarChar", valueCombo);
        }

        return (
            <Col key={idxForm}
                className={"mgt-15"}
                lg={getColSpan(formInfo.ColSpan, 6)}
                md={getColSpan(formInfo.ColSpan, 6)}
                sm={getColSpan(formInfo.ColSpan, 6)}
                xs={12}
            >
                <label>{formInfo.CaptionName84}</label>
                <Combo
                    dataSource={dataSource}
                    showClearButton={true}
                    placeholder={formInfo.CaptionName84}
                    controlType={formInfo.ControlType}
                    valueExpr={formInfo["valueExpr"]}
                    value={valueCombo}
                    disabled={disable}
                    showHeader={true}
                    displayExpr={formInfo["displayExpr"]}
                    onActionWhenSelectChange={(value) => {
                        const valueT = formInfo.ControlType === "CC" ? value : (value ? value[formInfo["valueExpr"]] : null);
                        this.setValue(valueT, formInfo.ControlType);
                        if (handleData) handleData(formInfo.ControlID, "VarChar", valueT);
                        if (controlChildrens && controlChildrens.length > 0 && clearCombo) {
                            let data = [];
                            for (let cChild of controlChildrens) {
                                clearCombo(cChild);
                                data.push([cChild.ControlID, "VarChar", null]);
                            }
                            handleData(data);
                        }
                    }}
                >
                    {formInfo.fields && formInfo.fields.map((field, idxField) => {
                        this.value = value;
                        return (
                            <Column key={idxField}
                                dataField={field.FieldName}
                                caption={field.Caption84}
                                calculateFilterExpression={(filterValue, selectedFilterOperation) => {
                                    return [field.FieldName, selectedFilterOperation, filterValue.normalize()]
                                }}
                                calculateCellValue={(e) => {
                                    if (!e) return null;
                                    return e[field.FieldName] && typeof e[field.FieldName] === "string" ? e[field.FieldName].normalize() : e[field.FieldName];
                                }}
                                visible={field.IsHide === 0} />
                        )
                    })}
                </Combo>
            </Col>
        );
    }
}

export default compose(connect((state) => ({
    listGroupChart: state.W94F1000.listGroupChart,
}), (dispatch) => ({
    W94F1000Actions: bindActionCreators(W94F1000Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles, { withTheme: true }))(W94F1002);
