/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/16/2020
 * @Example
 */

import React from 'react';
import Config from "../../config";
import ReactLoading from 'react-loading';
import {Typography, withStyles} from "@material-ui/core";
import queryString from 'query-string'
import {connect} from "react-redux";
import * as homeActions from "../../redux/home/home_actions";
import {bindActionCreators, compose} from "redux";

const styles = {
    content: {
        textAlign: "center",
        padding: 15
    }
};
class AuthPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loading !== this.props.loading && !this.props.loading) {
            this.checkAuth();
        }
    }

    checkAuth = () => {
        const query = queryString.parse(window.location.search);
        const {token} = query;
        const params = {
            token: token ? token : ""
        };
        Config.getToken = true;
        this.props.homeActions.getAuth(params, (error, data)=>{
            if (error){
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.onLogin(data);
            }
        });
    };

    onLogin = (data) => {
        let user = data.user || {};
        let lang = Config.getLocalStorage("langBI");


        if (!lang || lang.length !== 2){
            lang = "vi";
            Config.setLocalStorage('langBI', lang);
        }

        const expire = new Date().getTime() + 7*24*60*60*1000;

        Config.token = {
            id: data.token,
            expire: expire
        };
        user.type = 2;

        Config.setLocalStorage('PROFILEBI', JSON.stringify(user));
        Config.setLocalStorage('TOKENBI', JSON.stringify(Config.token));
        Config.setLocalStorage('BI_VIEW', "grid");

        //Get all menus
        this.props.homeActions.getMenu({language:Config.language},(errGetMenu, dataGetMenu)=>{
            if (errGetMenu){
                Config.popup.show('INFO', errGetMenu.message);
                return false;
            } else if (dataGetMenu){
                Config.setLocalStorage('MENUBI', JSON.stringify(dataGetMenu));
                this.redirectScreen();
            }
        });
        //End get all menus
    };

    redirectScreen = () => {
        const query = queryString.parse(window.location.search);
        const {voucher_id, route} = query;
        let pathname = route ? route : "/";
        pathname = pathname.indexOf("/") === 0 ? pathname.substring(1) : pathname;
        const pathinfo = {
            pathname: Config.getRootPath() + pathname,
            state: voucher_id ? {voucher_id: voucher_id} : null
        };
        Config.setLocalStorage('AUTHREDIRECTBI', JSON.stringify(pathinfo));
        window.location.reload();
    };

    render() {
        const {classes} = this.props;
        let label = "Đang chuyển tiếp, xin vui lòng chờ...";
        if (Config.language === "01") label = "Forward, please wait...";

        return (
            <div className="auth-page">
                <div className={"auth-page-content " + classes.content}>
                    <Typography variant={"h3"}>{label}</Typography>
                    <div className={"display_row align-center valign-middle"}>
                        <ReactLoading type={'bubbles'}
                                      width={'10%'}
                                      color={"#222B45"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(connect(null,
    (dispatch) => ({
        homeActions: bindActionCreators(homeActions, dispatch)
    })
), withStyles(styles))(AuthPage);
