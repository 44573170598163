/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @update 2017/08/12 10:37
 */

import React from 'react';
import Config from '../../config';
import {Image} from 'react-bootstrap';
import {browserHistory} from "react-router";
import Profile from "./profile";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as mainActions from "../../redux/main/main_actions";
import Search from "../search/search";
import LinkProducts from "../libs/dgn-core/link-products";

class Header extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tabNotify: "ALL",
            notifyLoading: false,
            totalNew: 0,
        };
        this.loadFullNotify = false;
        this.limitNotify = 10;
        this.scrollTop = 0;
        this.dataLoadNotify = {
            limit: this.limitNotify,
            skip: 0
        }
    }

    render(){
        const logoImg = Config.getSetting('LOGO_URL') ? Config.getSetting('LOGO_URL') : require('../../assets/images/general/logobi.svg');

        return(
            <div className="header-container">
                <div className={'display_row align-center view-logo'}>
                    <div className={'display_row align-center logo'} onClick={()=>{
                        browserHistory.push(Config.getRootPath() + 'dashboard');
                    }}>
                        {Config.getSetting('LOGO_URL') && <Image src={logoImg} />}
                        {!Config.getSetting('LOGO_URL') &&
                            <React.Fragment>
                                <Image src={logoImg}/>
                                {/*<span style={{marginLeft: 5, fontSize: '1.125rem', fontWeight: 500}}>*/}
                                {/*    <b>LEMON3</b>*/}
                                {/*    <span style={{fontWeight: 200}}>-BI</span>*/}
                                {/*</span>*/}
                            </React.Fragment>
                        }
                    </div>
                    <div className={'header-filter'}>
                        <Search ref={ref => Config.filters = ref}/>
                    </div>
                </div>

                {/* Link to products */}
                <LinkProducts />

                <div className={'display_row align-center'}>
                    <div className="profile-view">
                        <Profile />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
        filters: state.main.filters,
        badge: state.main.badge,
        listNoty: state.main.listNoty,
    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch)
    })
)(Header);
