/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @update 2017/08/12 10:37
 */

import React from 'react';

class Search extends React.Component{

    constructor(props){
        super(props);
        this.state={
            renderHTML:null,
            isShow: true,
        }
    }

    renderHTML = (html) => {
        const innerHtml = html();
        this.setState({
            renderHTML: innerHtml
        })
    };

    render(){
        const {renderHTML} = this.state;
        if(!renderHTML) return null;
        return renderHTML;
    }
}

export default Search;
//
// export default connect(state => ({
//
//     }),
//     (dispatch) => ({
//         mainActions: bindActionCreators(mainActions, dispatch)
//     })
// )(Search);
