/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2017/10/25 16:37
 * @update 2017/10/25 16:37
 * @file src/components/not-found/not-found.js
 */

import React, {Component} from 'react';
import Config from "../../config";
import {FormGroup, Col} from "reactstrap"

export default class ModuleNotFound extends Component {


    render() {

        return (
            <div className="login mrgt50">
                <img
                    src={require('./../../assets/images/icon-not-found.png')}
                    alt="" width={265} height={265}/>
                <FormGroup row className="text-center">
                    <Col>
                        <h2>{Config.lang("BI_Khong_tim_thay_trang")}</h2>
                    </Col>
                    <Col>
                        {Config.lang("BI_404_loi")}
                    </Col>
                </FormGroup>
                {/*{Config.lang("CRM_Chuc_nang_dang_duoc_phat_trien")}..*/}
            </div>
        )
    }
}
