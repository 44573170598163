/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/05 18:12
 * @update 2019/08/05 18:12
 */

module.exports = {
    api: process && process.env && process.env.REACT_APP_API ? process.env.REACT_APP_API : 'https://apricot.diginet.com.vn/api-bi',
    url: process && process.env && process.env.REACT_APP_WEB ? process.env.REACT_APP_WEB : 'https://apricot.diginet.com.vn/bi-dev',
    secret : process && process.env && process.env.REACT_APP_SECRET ? process.env.REACT_APP_SECRET :  'b7z2x8a4v2n9m3l5k9j3h5a8s4d3f4w3f1sy4g',

    cdn: process && process.env && process.env.REACT_APP_CDN_URL ? process.env.REACT_APP_CDN_URL : 'http://apricot.diginet.com.vn:6040/v1',
    secretCDN : process && process.env && process.env.REACT_APP_CDN_SECRET ? process.env.REACT_APP_CDN_SECRET :  'd3hc5d0k5LnJx9Cvq3NwOe3uGy0cW9ja2kv3dg',

    prefixLang : process && process.env && process.env.REACT_APP_PREFIXLANG ? process.env.REACT_APP_PREFIXLANG :  'BI_',
    companyCode : process && process.env && process.env.REACT_APP_COMPANYCODE ? process.env.REACT_APP_COMPANYCODE :  'APRICOT',
    productID : process && process.env && process.env.REACT_APP_PRODUCTID ? process.env.REACT_APP_PRODUCTID :  'WBI',

    mookAPI: process && process.env && process.env.REACT_APP_MOOK_API ? process.env.REACT_APP_MOOK_API :  'NO',
    keyEnv: process && process.env && process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV :  'NO',
};
