/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 20/0/2019
 */

import React from 'react'
import PropTypes from 'prop-types';
import EventTracking from "../tracking/event";

export class PageContainer extends React.PureComponent{
    render() {
        const {tracking, id,title,children} = this.props;
        return (
            <div id={id} className="page-container">
                {title && <div className="grid-title">{title}</div>}
                <EventTracking action={"FORM"} label={tracking}/>
                {children}
            </div>
        )
    }
}
PageContainer.propTypes = {
    tracking: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
}
export default PageContainer