/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/23 16:50
 * @update 2019/01/23 16:50
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/es/Button/Button";
import Config from "../../../config";

class PagingCrm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalItems: props.totalItems || 0,
            itemPerPage: props.itemPerPage,
            currentPage: props.currentPage && props.currentPage !== -1 ? props.currentPage : 1,
            listPerPage: props.listPerPage || [10, 15, 20, 30, 50],
            disableFirst: true,
            disablePrev: true,
            disableNext: !(props.totalItems > props.itemPerPage),
            disableLast: !(props.totalItems > props.itemPerPage),
            typeShort: props.typeShort
        };

        this.timeout = null;
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.props.totalItems !== nextProps.totalItems || (this.props.skipPerPage !== nextProps.skipPerPage && nextProps.skipPerPage === 0)) {
            const lastPage = this.getLastPage(nextProps.totalItems, this.state.itemPerPage);
            const _initCurrentPage = ((nextProps.currentPage && nextProps.currentPage !== -1) || nextProps.currentPage === 0) ? nextProps.currentPage : this.state.currentPage;
            const currentPage = _initCurrentPage + 1 > lastPage ? lastPage - 1 : _initCurrentPage;
            this.setState({
                totalItems: nextProps.totalItems,
                listPerPage: nextProps.listPerPage,
                // disableFirst: true,
                // disablePrev: true,
                disableNext: nextProps.totalItems <= (currentPage) * nextProps.itemPerPage,
                disableLast: nextProps.totalItems <= (currentPage) * nextProps.itemPerPage,
            });

            if (nextProps.skipPerPage === 0) {
                this.setState({
                    currentPage: 1
                });
                return
            }
            if (_initCurrentPage !== -1) {
                this.setState({
                    currentPage: currentPage,
                });
            }
            if (_initCurrentPage + 1 > lastPage) {
                this.onChangePage(lastPage - 1);
            }
        }
    }

    getLastPage(totalItems, itemPerPage) {
        let lastPage = 0;

        if (totalItems && totalItems >= 0 && itemPerPage && itemPerPage > 0) {
            lastPage = Math.ceil(totalItems / itemPerPage);
            return lastPage;
        }

        return lastPage + 1;
    }

    onChangePage(page) {
        const {totalItems, itemPerPage} = this.state;
        const lastPage = this.getLastPage(totalItems, itemPerPage);

        if (page < 1) page = 1;
        if (page >= lastPage) page = lastPage;

        let disableFirst = false;
        let disablePrev = false;
        let disableNext = false;
        let disableLast = false;

        // If last page = 1. Disable all
        if (lastPage === 1) {
            disableFirst = true;
            disablePrev = true;
            disableNext = true;
            disableLast = true;
        }

        // If current page = last page. Disable Next and Last
        if (page >= lastPage) {
            disableNext = true;
            disableLast = true;
        }

        // If current page = 0. Disable Previous and First
        if (1 === page) {
            disableFirst = true;
            disablePrev = true;
        }

        this.setState({
            currentPage: page,
            disableFirst: disableFirst,
            disablePrev: disablePrev,
            disableNext: disableNext,
            disableLast: disableLast
        }, () => {
            if (this.props.onChangePage) this.props.onChangePage(page - 1);
        });
    }

    onChangePerPage(per) {
        const {totalItems} = this.state;
        const lastPage = this.getLastPage(totalItems, per);

        per = parseInt(per, 10);

        let disableNext = false;
        let disableLast = false;

        // If last page = 1. Disable all
        if (lastPage === 1) {
            disableNext = true;
            disableLast = true;
        }

        this.setState({
            itemPerPage: per,
            currentPage: 0,
            disableFirst: true,
            disablePrev: true,
            disableNext: disableNext,
            disableLast: disableLast
        }, () => {
            if (this.props.onChangePerPage) this.props.onChangePerPage(per);
        });
    }

    onTypePaging = (e) => {
        const {value} = e.target;
        this.setState({
            currentPage: value
        });
        if (this.timeout) clearTimeout(this.timeout);
        const {totalItems, itemPerPage} = this.state;
        const lastPage = this.getLastPage(totalItems, itemPerPage);
        this.timeout = setTimeout(() => {
            // const value = this.refs['input-page'].value;
            const page = (!value || parseInt(value) < 0) ? 1 : (parseInt(value) > lastPage ? lastPage : parseInt(value));
            // this.refs['input-page'].value = page;
            this.onChangePage(page);
        }, 500);
    };

    render() {

        const {currentPage, totalItems, itemPerPage, disableFirst, disablePrev, disableNext, disableLast, typeShort} = this.state;
        const lastPage = this.getLastPage(totalItems, itemPerPage);

        if (!this.props.totalItems) return null;

        // console.log('currentPage',currentPage);
        // if (this.refs['input-page'] && parseInt(this.refs['input-page'].value) !== (currentPage + 1)) {
        //     this.refs['input-page'].value = currentPage + 1;
        // }
        // console.log('input', currentPage)
        return (
            <div className="paging-crm">
                <div>
                    <Button className={"first"}
                            disabled={disableFirst}
                            onClick={() => {
                                this.onChangePage(1)
                            }}>
                        <i className="fas fa-step-backward"/>
                    </Button>
                </div>
                <div>
                    <Button className={"prev"}
                            disabled={disablePrev}
                            onClick={() => {
                                this.onChangePage(currentPage - 1)
                            }}>
                        <i className="fas fa-backward"/>
                    </Button>
                </div>
                {!typeShort && <span className={"slash"}>|</span>}
                {!typeShort && Config.lang("CRM_Trang")}
                <input className="pageOf"
                       ref={'input-page'}
                       type={"text"}
                       style={{minWidth: "40px"}}
                    // value={cPage}
                       value={currentPage}
                    // defaultValue={1}
                       onChange={(e) => this.onTypePaging(e)}/> / {lastPage}
                {!typeShort && <span className={"slash"}>|</span>}
                <div>
                    <Button className={"next"}
                            disabled={disableNext}
                            onClick={() => {
                                this.onChangePage(currentPage + 1)
                            }}>
                        <i className="fas fa-forward"/>
                    </Button>
                </div>
                <div>
                    <Button className={"last"}
                            disabled={disableLast}
                            onClick={() => {
                                this.onChangePage(lastPage)
                            }}>
                        <i className="fas fa-step-forward"/>
                    </Button>
                </div>
                {!typeShort && <span className={"slash"}>|</span>}
                <span className="txt-line-number">{!typeShort && Config.lang("CRM_So_dong_tren_mot_trang")}</span>
                <select className="pagePer"
                        defaultValue={this.state.itemPerPage}
                        onChange={(e) => this.onChangePerPage(e.target.value)}>
                    {this.state.listPerPage && this.state.listPerPage.map((o, i) => {
                        return (<option key={i} value={o}>{o}</option>);
                    })}
                </select>
                {!typeShort && <span className={"slash hide-mobile"}>|</span>}&nbsp;
                <Button className={"last"}
                        onClick={() => {
                            this.onChangePage(currentPage)
                        }}>
                    <i className="fas fa-sync-alt"/>
                </Button> &nbsp;
                <span className={"slash hide-mobile"}>|</span>
                <span className="hide-mobile"><b> {Config.lang("CRM_Tong")}: {totalItems}</b></span>
            </div>
        );
    }
}

PagingCrm.propTypes = {
    totalItems: PropTypes.number,
    itemPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    listPerPage: PropTypes.array,
    typeShort: PropTypes.bool,

    onChangePage: PropTypes.func,
    onChangePerPage: PropTypes.func,
};

export default PagingCrm;